import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import {
  createMSEvent,
  deleteMSEvent,
  editMSEvent,
  getMSAllCalendar,
  getMSAllCalendarEvents,
  setMSCredentials,
  setMSUnlinkCredentials
} from 'src/redux/slices/microsoft';
import { gDate } from 'src/utils/formatTime';
import { MICROSOFT_CONFIG } from 'src/config';

import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';

export const useMicrosoft = () => useContext(MicrosoftContext);

export default function MicrosoftProvider({ children }) {
  const { user, isAuthenticated: isAuth, profileLoading, updateLocalProfile } = useAuth();
  const dispatch = useDispatch();

  const [userMetas, setUserMetas] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [msl, setMsl] = useState(null);

  const [calendars, setCalendars] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);

  const isAuthenticated = useMemo(() => {
    return Boolean(userMetas && gDate(userMetas?.expiresOn) > new Date());
  }, [userMetas]);

  const defaultCalendar = useMemo(() => calendars?.find((el) => el?.isDefaultCalendar) || null, [calendars]);

  useEffect(() => {
    if (isAuth) {
      setUserMetas(user?.microsoftCredential);
      setAccessToken(user?.microsoftCredential?.accessToken || null);
      if (isAuthenticated) {
        getAllCalendarEvents();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, isAuthenticated, user?.microsoftCredential]);

  //#region base
  const authHandler = (err, data, msal) => {
    if (!err && data && !msl) {
      setMsl(msal);
      setUserMetas(data);
      setAccessToken(data?.accessToken);
      //console.log('misc auth called !');
      dispatch(
        setMSCredentials({
          userId: user?.id,
          data,
          onResolve: () => getAllCalendarEvents(data?.accessToken),
          onReject: () => { }
        })
      );
    }
  };

  const logout = () => {
    msl?.clearCache();

    setMsl(null);
    setUserMetas(null);
    setAccessToken(null);
    setEvents([]);
    updateLocalProfile('microsoftCredential', null);

    // msl?.logoutAsync()

    dispatch(
      setMSUnlinkCredentials({
        userId: user?.id,
        onResolve: () => { }
      })
    );
  };
  //#endregion

  const getAllCalendar = (token = null) => {
    dispatch(
      getMSAllCalendar({
        accessToken: accessToken || token,
        onResolve: (events) => {
          setCalendars(events);
        },
        onReject: (error) => {
          console.error(error);
        }
      })
    );
  };

  //#region event
  const getAllCalendarEvents = (token = null) => {
    setEventsLoading(true);
    dispatch(
      getMSAllCalendarEvents({
        accessToken: accessToken || token,
        onResolve: (events) => {
          setEvents(uniqBy(events, 'id'));
          setEventsLoading(false);
        },
        onReject: (error) => {
          console.error(error);
          setEventsLoading(false);
        }
      })
    );
    if (calendars?.length === 0) {
      getAllCalendar(token);
    }
  };

  const createEvent = (event) => {
    dispatch(
      createMSEvent({
        accessToken,
        calendarId: defaultCalendar?.id,
        event: {
          subject: 'NOrd asdfr',
          body: {
            contentType: 'HTML',
            content: 'Does mid month work for you?'
          },
          start: {
            dateTime: '2022-10-16T12:00:00',
            timeZone: 'Pacific Standard Time'
          },
          end: {
            dateTime: '2022-10-18T12:00:00',
            timeZone: 'Pacific Standard Time'
          },
          location: {
            displayName: "Harry's Bar"
          },
          attendees: [
            {
              emailAddress: {
                address: 'adelev@contoso.onmicrosoft.com',
                name: 'Adele Vance'
              },
              type: 'required'
            }
          ]
          // transactionId: '7E163156-7762-4BEB-A1C6-729EA81755A7'
        },
        onResolve: (event) => {
          getAllCalendarEvents();
        },
        onReject: (error) => {
          console.error(error);
        }
      })
    );
  };

  const editEvent = (event, { onResolve, onReject } = {}) => {
    // const ex={
    //     id: "AQMkADAwATM0MDAAMS00MTEANS1mZTgyLTAwAi0wMAoARgAAA3QE4Kg5EwxLre4Bl9wizecHAFhAu06RLP5Lu8A5h5M1UsoAAAIBDQAAAFhAu06RLP5Lu8A5h5M1UsoAAAGHtg4AAAA=",
    //     subject: 'NOrd astic',
    //     body: {
    //         contentType: 'HTML',
    //         content: 'Does mid month work for you?'
    //     },
    //     start: {
    //         dateTime: '2022-10-16T12:00:00',
    //         timeZone: 'Pacific Standard Time'
    //     },
    //     end: {
    //         dateTime: '2022-10-18T12:00:00',
    //         timeZone: 'Pacific Standard Time'
    //     },
    //     location: {
    //         displayName: 'Harry\'s Bar'
    //     },
    //     attendees: [
    //         {
    //             emailAddress: {
    //                 address: 'adelev@contoso.onmicrosoft.com',
    //                 name: 'Adele Vance'
    //             },
    //             type: 'required'
    //         }
    //     ],
    // }

    dispatch(
      editMSEvent({
        accessToken,
        calendarId: defaultCalendar?.id,
        event,
        onResolve: (event) => {
          getAllCalendarEvents();
          onResolve && onResolve();
        },
        onReject: (error) => {
          console.error(error);
          onReject && onReject();
        }
      })
    );
  };

  const editEventFromStandard = (event, { onResolve, onReject } = {}) => {
    const { id, title, allDay, eventRappel, type, start, end } = event;
    const timeZone = DateTime.now().zoneName;
    //console.log('timezone', timeZone);
    editEvent(
      {
        id,
        subject: title,
        start: {
          dateTime: start,
          timeZone
        },
        end: {
          dateTime: end,
          timeZone
        }
      },
      { onResolve, onReject }
    );
  };

  const deleteEvent = (eventId, { onResolve, onReject } = {}) => {
    dispatch(
      deleteMSEvent({
        accessToken,
        calendarId: defaultCalendar?.id,
        eventId,
        onResolve: (event) => {
          getAllCalendarEvents();
          onResolve && onResolve();
        },
        onReject: (error) => {
          console.error(error);
          onReject && onReject();
        }
      })
    );
  };
  //#endregion

  // console.group()
  // console.log('user', user)
  // console.log('isAuthenticated', isAuthenticated)
  // console.log('client id', MICROSOFT_CONFIG.clientId)
  // console.log('msl', msl)
  // console.log('resp', userMetas)
  // console.log('accessToken', accessToken)
  // console.log('calendars', calendars)
  // console.log('defaultCalendar', defaultCalendar)
  // console.log('events', events)
  // console.groupEnd()

  const store = {
    authHandler,
    logout,
    getAllCalendar,
    getAllCalendarEvents,
    createEvent,
    editEvent,
    editEventFromStandard,
    deleteEvent,
    msl,
    userMetas,
    accessToken,
    events,
    isAuthenticated,
    loading: profileLoading || eventsLoading,
    loginBtnProps: {
      clientId: MICROSOFT_CONFIG.clientId,
      authCallback: authHandler,
      redirectUri: window.location.origin,
      graphScopes: [
        'User.Read',
        'Calendars.Read'
        // 'Calendars.Read.Shared',
        // 'Calendars.ReadWrite',
        // 'Calendars.ReadWrite.Shared',
      ]
    }
  };

  return <MicrosoftContext.Provider value={store}>{children}</MicrosoftContext.Provider>;
}

const MicrosoftContext = createContext({
  authHandler: () => { },
  logout: () => { },
  getAllCalendar: () => { },
  getAllCalendarEvents: () => { },
  createEvent: (event) => { },
  editEvent: (event) => { },
  editEventFromStandard: (event, { onResolve, onReject }) => { },
  deleteEvent: (eventId, { onResolve, onReject }) => { },
  msl: null,
  userMetas: null,
  accessToken: null,
  events: [],
  isAuthenticated: false,
  loginBtnProps: {},
  loading: false
});
