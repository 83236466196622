import React, { useState } from 'react';
import { CustomMenuPopover } from './MenuPopover';
import { DatePicker, Button } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';

export default function DateTimeRangeSelector({
  open,
  onClose,
  anchorEl,
  onChange,
  deb,
  end,
  hideTime = false,
  dataInterval = null,
  onReset = () => { }
}) {
  const disabledDate = (current) => {
    if (!dataInterval || isEmpty(dataInterval)) return false;

    const { start, end } = dataInterval;
    const startMoment = moment(start?.seconds * 1000);
    const oneDay = 24 * 60 * 60 * 1000;
    const endMoment = moment(end?.seconds * 1000 + oneDay);

    return current <= startMoment || current >= endMoment;
  };

  const [due, setDue] = useState([moment(deb || undefined), moment(end || undefined)]);
  const [selectedDates, setSelectedDates] = useState(due);
  let temp = [due.at(0)._d, due.at(1)._d];

  const handleChange = (val) => {
    if (!val) {
      setDue([null, null]);
      setSelectedDates([null, null]); // Mettre à jour les dates sélectionnées
      temp = [null, null];
      onChange([null, null]);
      return;
    }

    // Vérifiez si la date de fin est vide ou égale à la date de début
    if (!val[1]) {
      // Si oui, ajoutez une journée à la date de fin
      val[1] = val[0];
    }

    // if (moment(val[0]).isSame(val[1], 'day')) {
    //   val[1] = val[0];
    // }

    const newDue = [val.at(0)._d, val.at(1)._d];
    setDue(val);
    setSelectedDates(newDue); // Mettre à jour les dates sélectionnées
    temp = newDue;
    onChange(newDue);
  };

  const handleClose = () => {
    onClose(temp);
  };

  const handleReset = () => {
    setDue([null, null]);
    setSelectedDates([null, null]); // Mettre à jour les dates sélectionnées
    temp = [null, null];
    onReset();
  };

  return (
    <CustomMenuPopover
      className="this-is-the-probleme"
      disablePortal
      open={open}
      width="max-content"
      anchorEl={anchorEl}
      disabledArrow
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <DatePicker.RangePicker
        {...(!hideTime && { showTime: { format: 'HH:mm' } })}
        open={open}
        onOpenChange={handleClose}
        onChange={handleChange}
        value={due}
        defaultValue={due}
        disabledDate={disabledDate}
        format="YYYY-MM-DD HH:mm"
      />
      <Button onClick={handleReset}>Reset</Button>
    </CustomMenuPopover>
  );
}
