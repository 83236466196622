import useAuth from 'src/hooks/useAuth'
import useMessageHistory from "./useMessageHistory";


export default function useConversationUnreadMessages(conversationId) {
    const {user} = useAuth();
    const {list = []} = useMessageHistory(conversationId);


    const result = list?.filter(el => {
        if (el?.senderId === user?.id) {
            return false
        }

        const isPresent = el?.timeLine?.find(e => e?.userId === user?.id)

        return !isPresent;
    })?.length || 0


    return result;
}