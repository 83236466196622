import { IconButton, Typography, Stack, Divider, List } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { useConverseProvider } from './ConverseProvider';
import { UserItem } from './ConversationCreate';

export default function ConverseDetails({ onClose }) {
  const { currentConversation, goToRoom } = useConverseProvider();
  const { participants = [] } = currentConversation;

  const goBack = () => goToRoom(currentConversation);

  return (
    <>
      <Stack direction={'row'} px={2} py={1} justifyContent={'space-between'}>
        <Stack direction={'row'}>
          <IconButton onClick={goBack}>
            <ArrowBackIos />
          </IconButton>

          <Stack direction={'row'} spacing={0.5}>
            <Stack justifyContent={'center'} height={1}>
              <Typography fontWeight={'bold'}>Liste des participants</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <List dense>
        {participants.map((e) => (
          <UserItem key={e.id} user={e} />
        ))}
      </List>
    </>
  );
}
