import {keys} from "lodash";
import {gDate} from "../utils/formatTime";
import {auth} from "../contexts/FirebaseContext";

export const transformHistory = (store, conversationId, key = 'record') => {
    const data = store[getHistoryRef(conversationId)];

    const raw = data || [];
    const result = [];

    if (data)
        raw.forEach(el => {
            const record = el[key] || {};

            keys(record).forEach(messageId => {
                const timeLine = (record[messageId] || [])?.map(e => ({...e, readAt: gDate(e?.readAt)}))

                result.push({
                    timeLine,
                    messageId,
                    senderId: el?.senders && el?.senders[messageId] || null,
                })
            })

        })

    return result?.filter(el => el?.senderId);
}

export const areHistoryLoading = (store, conversationIds = []) => {
    let loading = true;

    conversationIds?.forEach(id => {
        if (store[getHistoryRef(id)])
            loading = false
    })

    return loading;
}

export const getHistoryRef = (conversationId) => {
    return `conversations_messages_histories_${conversationId}`
}

export const genHistoryQueries = (conversationIds = []) => {
    return conversationIds?.map(id => (
        {
            collection: `conversations/${id}/history`,
            storeAs: getHistoryRef(id),
        }
    ))
}


export const getConversationUnread = (timeLine = [], conversationId, userId) => {

    // if (timeLine.length !== 0) {
    //     console.group("getConversationUnreadCount")
    //     //console.log(conversationId)
    //     //console.log(timeLine)
    //     console.groupEnd()
    // }

    return timeLine
        ?.filter(el => el?.senderId && (el?.senderId !== userId))
        ?.filter(el => !el?.timeLine?.length !== 0 && !el?.timeLine?.find(e => e?.userId === userId))

}

export const getConversationUnreadCount = (timeLine = [], conversationId, userId) => {
    return getConversationUnread(timeLine, conversationId, userId)?.length || 0
}

export const getConversationUnreadIds = (timeLine = [], conversationId, userId) => {
    return getConversationUnread(timeLine, conversationId, userId)?.map(el => el?.messageId) || [];
}

export const getUnmarkedReceivedMessageId = (unreadIds = [], _, timeline = []) => {
    const userId = auth?.currentUser?.uid;
    const list = []

    unreadIds.forEach(id => {
        const predicate = timeline?.find(el => el?.messageId === id);
        const canAdd = Boolean(!predicate || predicate && !predicate?.timeLine?.find(el => el?.userId === userId));

        if (canAdd) {
            list.push(id)
        }

        //
        // console.group(id)
        // console.log(userId)
        // console.log(timeline)
        // console.log(predicate)
        // console.log(canAdd)
        // console.groupEnd()

    })

    return list;
}