import { createContext, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

import { keys, isFunction } from 'lodash';
import { CLOUD_MAIL_API_KEY, MICROSOFT_CONFIG } from 'src/config';
import { gDate } from 'src/utils/formatTime';
import {
  deleteMsMail,
  disconnectAccount,
  getMails,
  getMsMailAttachment,
  markMailAsRead,
  markMailAsUnread,
  persistToken,
  sendMsMail,
  toggleMsMailImportance,
  updateAccountData,
  updateMailsInFolder,
  updateMailFolder,
  updateMailFolderRemote,
  updateMsMailFlag,
  saveToDraft,
  deleteCompletyMail,
  deleteDraft,
  deleteDraftById,
  addToSpam,
} from 'src/redux/slices/microsoftMail';
import { popupWindow } from 'src/utils/windows';
import { MAIL_PLATFORM } from 'src/section/mail/MailContext';

export const useMicrosoftMail = () => useContext(MicrosoftMailContext);

export const MicrosoftMailProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const loadingSnap = useSelector((state) => state?.microsoftMail?.loading ?? []);
  const accountsSnap = useSelector((state) => {
    const accounts = state?.microsoftMail?.accounts ?? {};
    //map acounts and if un acount have not id or it empty, delete it
    keys(accounts).forEach((email) => {
      if (!accounts[email]?.id) {
        delete accounts[email];
      }
    });
    return accounts;
  });
  const mailsSnap = useSelector((state) => state?.microsoftMail?.mails ?? {});
  const folderSnap = useSelector((state) => state?.microsoftMail?.folders ?? {});
  const accounts = useMemo(
    () => keys(accountsSnap)?.map((email) => ({ ...accountsSnap[email], email })),
    [accountsSnap]
  );

  const [btnInteraction, setBtnInteraction] = useState({ clicked: 0, reacted: 0 });

  const [listeners, setListeners] = useState({});

  const authHandler = (err, data, msal) => {
    if (!err) {
      const email = data?.idTokenClaims?.email;
      const isExpired = hasAccountExpired(accountsSnap[email]);

      const base = {
        token: data?.accessToken || null,
        expire: gDate(data?.expiresOn),
        platform: 'microsoft',
        tenantId: data?.tenantId,
        scopes: data?.scopes || [],
        user: {
          displayName: data?.account?.name,
          email: data?.idTokenClaims?.email
        }
      };
      const result = {
        [email]: base
      };

      //console.log('authHandler', data);
      //console.log('auth try', result);

      if (isExpired) {
        dispatch(
          persistToken({
            email,
            result,
            userId: user?.id
          })
        );
        // console.log("btnInteraction", btnInteraction)

        if (btnInteraction.reacted === 0 || btnInteraction.clicked > btnInteraction.reacted) {
          emitChangeToListeners('login', { ...base, email });
        }
      } else {
        if (btnInteraction.reacted === 0 || btnInteraction.clicked > btnInteraction.reacted) {
          enqueueSnackbar('Ce compte a déjà été lié', { variant: 'warning' });
        }
      }

      setBtnInteraction((prevState) => {
        const reacted = prevState.reacted + 1;

        return {
          ...prevState,
          clicked: reacted,
          reacted
        };
      });
    }
  };

  const _saveToDraft = (account, mailData, onResolve) => {
    //console.log('save to draft', account, mailData);
    dispatch(
      saveToDraft({
        account: account,
        email: mailData,
        onResolve,
      })
    );
  };


  const logout = (account) => {
    //console.log('logout', account);
    dispatch(disconnectAccount(account));
    // popupWindow(`https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`, 'logout', window, 400, 500)
  };

  const login = () => {
    const clientId = MICROSOFT_CONFIG.clientId;

    const redirectUri = `${CLOUD_MAIL_API_KEY}/gmail/signin`;
    const scope = 'openid User.Read email Mail.ReadWrite Mail.Send offline_access'.toLowerCase();

    const state = JSON.stringify({
      userId: user?.id,
      redirect_uri: `${window.location.origin}/mail/refresh`,
      parent: `${CLOUD_MAIL_API_KEY}/gmail/signin`
    });

    const url = `${MICROSOFT_CONFIG.BASE_URL}/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${scope}&state=${state}`;

    //console.log({ url, redirectUri });

    popupWindow(url, 'logout', window, 400, 500);
  };
  const updateAccount = (email = '', data = {}) => {
    dispatch(updateAccountData({ email, data, userId: user?.id }));
  };

  /**
   *
   * @param email {string}
   * @param mailData {{attachments: string[], subject: string, from: string, cc: string, to: string, body: string}}
   * @param onResolve
   */
  const sendMail = (email, mailData, onResolve) => {
    dispatch(
      sendMsMail({
        account: accountsSnap[email],
        email: mailData,
        onResolve
      })
    );
  };

  const _updateMailFlag = (account, mail, currentFlag, nextFlag, onResolve, onReject) => {
    // const email = action.payload.email;
    // const oldFlag = action.payload.oldFlag;
    // const folder = action.payload.folder;
    // const mailId = action.payload.mailId;
    // const flag = action.payload.flag;

    dispatch(
      updateMsMailFlag({
        account,
        mailId: mail?.id,
        oldFlag: currentFlag,
        nextFlag: nextFlag,
        folderType: mail?.folderType
      })
    );
  };


  const getEmails = (email, folderType) => {
    dispatch(
      getMails({
        account: accountsSnap[email],
        folderType
      })
    );
  };

  const _deleteDraft = (account, mailData, onResolve, onReject) => {
    dispatch(
      deleteDraftById({
        account: account,
        draftId: mailData?.id,
        onResolve,
        onReject
      })
    );
  };


  const getMailAttachments = (account, folderType, mailId, onResolve) => {
    // console.log('level 2')
    dispatch(
      getMsMailAttachment({
        account,
        folderType,
        mailId,
        onResolve
      })
    );
  };

  const _updateMailFolder = (account, mail, currentFolder, nextFolder, onResolve, onReject) => {
    //console.log('update mail folder', account, currentFolder, nextFolder);
    // const email = action.payload.email;
    // const currentFolder = action.payload.currentFolder;
    // const nextFolder = action.payload.nextFolder;
    // const mailId = action.payload.mailId;
    dispatch(
      updateMailFolderRemote({
        email: account?.user?.email,
        currentFolder,
        nextFolder,
        mailId: mail?.id,
      })
    );
  };

  const _deleteCompletyMail = (account, folder, mailId, onResolve) => {
    dispatch(
      deleteCompletyMail({
        account,
        folderType: folder,
        mailId,
        onResolve,

      })
    );
  };

  const updateMail = (account, folder, mailId, values) => {
    dispatch(
      updateMailsInFolder({
        email: account?.user?.email,
        folder,
        mailId,
        values
      })
    );
  };
  const deleteMail = (account, folderType, mailId, onResolve) => {
    dispatch(
      deleteMsMail({
        account,
        folderType,
        mailId,
        onResolve
      })
    );
  };
  const markAsRead = (email, folderType, mailId) => {
    dispatch(
      markMailAsRead({
        account: accountsSnap[email],
        folderType,
        mailId
      })
    );
  };

  const markAsUnread = (email, folderType, mailId) => {
    dispatch(
      markMailAsUnread({
        account: accountsSnap[email],
        folderType,
        mailId
      })
    );
  };

  const toggleMailImportance = (account, folderType, mailId, isImportant, onResolve) => {
    dispatch(
      toggleMsMailImportance({
        account,
        folderType,
        mailId,
        isImportant,
        onResolve
      })
    );
  };

  const addToSpam = (account, folderType, mailId, onResolve) => {
    dispatch(
      addToSpam({
        account,
        folderType,
        mailId,
        onResolve
      })
    );
  };

  const addListeners = (key, callback) => {
    //console.log('add listeners', key);
    setListeners((prevState) => ({ ...prevState, [key]: callback }));
  };

  const removeListener = (key) => {
    setListeners((prevState) => {
      const copy = { ...prevState };
      delete copy[key];
      return copy;
    });
  };

  const onClickAuthBtn = () => {
    setBtnInteraction((prevState) => ({ ...prevState, clicked: prevState.clicked + 1 }));
  };
  const emitChangeToListeners = (eventType, data) => {
    //console.log('emmit changes listeners | type ', eventType, ' data ', data);
    setListeners((prevState) => {
      //console.log('listners list ', prevState);

      return prevState;
    });
    keys(listeners).forEach((key) => {
      const callback = listeners[key];

      //console.log('listeners ', key, ' is a function ', isFunction(callback));
      isFunction(callback) && callback(eventType, data);
    });
  };

  // useEffect(() => {
  //     const bc = new BroadcastChannel('mail_chanel');
  //
  //     bc.onmessage = ev => {
  //         const account = {
  //             ...ev?.data,
  //             email: ev?.data?.user?.email
  //         }
  //
  //         emitChangeToListeners('login', account)
  //
  //         //console.log("channel", ev?.data);
  //     }
  //
  // }, [])

  const store = {
    accounts,
    mailsSnap,
    updateAccount,
    login,
    logout,
    loadingSnap,

    sendMail,
    getEmails,
    getMailAttachments,
    markAsRead,
    markAsUnread,
    deleteMail,
    toggleMailImportance,
    updateMail,
    updateMailFolder: _updateMailFolder,
    addListeners,
    removeListener,
    saveToDraft: _saveToDraft,
    deleteDraft: _deleteDraft,
    onClickAuthBtn,

    folderSnap,
    updateMailFlag: _updateMailFlag,
    deleteCompletyMail: _deleteCompletyMail,
    loginBtnProps: {
      clientId: MICROSOFT_CONFIG.clientId,
      authCallback: authHandler,
      redirectUri: window.location.origin,
      graphScopes: ['User.Read', 'email', 'Mail.ReadWrite', 'Mail.Send', 'offline_access']
    }
  };

  return <MicrosoftMailContext.Provider value={store}>{children}</MicrosoftMailContext.Provider>;
};

export const hasAccountExpired = (data) => {
  return !data ? true : gDate(data?.expire) < new Date();
};

const MicrosoftMailContext = createContext({
  accounts: [],
  mailsSnap: {},
  folderSnap: {},
  loginBtnProps: {},
  updateAccount: (email = '', data = {}) => Promise.all(),
  loadingSnap: [],
  /**
   * @param onResolve
   * @param email {string}
   * @param mailData {{attachments: string[], subject: string, from: string, to: string, cc: string, body: string}} * @returns {*}
   */
  sendMail: (email, mailData, onResolve) => Promise.all(),
  addToSpam: (account, folderType, mailId, onResolve) => Promise.all(),
  getEmails: (email, folderType) => Promise.all(),
  markAsRead: (email, folderType, mailId) => Promise.all(),
  markAsUnread: (email, folderType, mailId) => Promise.all(),
  deleteMail: (account, folder, mailId, onResolve) => Promise.all(),
  getMailAttachments: (email, folderType, mailId, onResolve) => Promise.all(),
  updateMail: (account, folder, maiId, values) => Promise.all(),
  updateMailFolder: (account, mail, currentFolder, nextFolder, onResolve, onReject) => Promise.all(),
  addListeners: (key, callback) => Promise.all(),
  removeListener: (key) => Promise.all(),
  saveToDraft: (account, mailData, onResolve) => Promise.all(),
  deleteDraft: (account, mailData, onResolve, onReject) => Promise.all(),
  updateMailFlag: (account, mail, currentFlag, nextFlag, onResolve, onReject) => Promise.all(),
  deleteCompletyMail: (account, folder, mailId, onResolve) => Promise.all(),
  onClickAuthBtn: () => Promise.all(),
  toggleMailImportance: (account, folder, mailId, isImportant, onResolve) => Promise.all()
});
