import { Divider, Paper, Skeleton, Stack } from '@mui/material';
import React from 'react';

export default function MailV2MessageDetailSkeleton() {
  return (
    <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack
        sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
      >
        <Stack sx={{ p: 0, display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
          <Stack direction="column" spacing={0}>
            <Skeleton width={200} />
            <Skeleton width={400} />
            <Skeleton width={220} />
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Stack sx={{ p: 3 }} height={'60vh'} overflow={'auto'}>
        <Skeleton width={300} height={50} />
        <Skeleton height={500} />
        <Skeleton height={100} />
      </Stack>
    </Paper>
  );
}
