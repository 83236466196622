import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Close, Add } from '@mui/icons-material';

import Scrollbar from '../../components/Scrollbar';

import createAvatar from '../../utils/createAvatar';
import { MAvatar } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { SelectCollaborates } from '../project/ProjectCardOPtion';
import LoadingView from '../../components/LoadingView';

import { createConversation } from 'src/redux/slices/chat';
import useConversation, { transformUser } from 'src/hooks/useConversation';
import useToggle from 'src/hooks/useToggle';
import { useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { useConverseProvider } from './ConverseProvider';

export default function ConversationCreate() {
  const ref = useRef();
  const { user } = useAuth();
  const dispatch = useDispatch();
  // const {}=useSnack
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [participants, setParticipants] = useState([]);
  const { handleOpen, handleClose, open } = useToggle();
  const { goToHome, goToRoom } = useConverseProvider();

  const { findByParticipants } = useConversation();

  const isGroup = participants.length >= 2;

  const isValid = useMemo(() => {
    if (isGroup && name.trim().length === 0) {
      return false;
    }

    return Boolean(participants.length !== 0);
  }, [isGroup, participants, name]);

  const handleRemove = (id) => {
    setParticipants(participants?.filter((el) => el?.id !== id));
  };

  const handleCreate = () => {
    const users = [transformUser(user), ...participants];
    const existing = findByParticipants(users);

    if (existing && existing?.length !== 0) {
      goToRoom(existing[0]);
      return;
    }

    const ids = users?.map((el) => el?.id);

    let _name = '';

    participants.forEach((e, idx) => {
      if (e?.id !== user?.id) _name += `${e?.name}${idx + 1 === participants?.length ? '' : ','} `;
    });

    if (_name[_name?.length - 2] === ',') {
      _name = _name?.substring(0, _name?.length - 2);
    }

    const data = {
      createdAt: new Date(),
      name: name.trim().length === 0 ? _name : name,
      participants: users,
      participantsId: ids,
      lastMessage: '',
      lastSenderId: '',
      lastSend: new Date(),
      type: 'ONE_TO_ONE',
      unreadCount: 0
    };

    const callback = (conversation) => {
      goToRoom(conversation);
      setLoading(false);
    };

    if (isValid) {
      setLoading(true);
      dispatch(createConversation(data, callback, () => setLoading(false)));
    }
  };

  return (
    <Box height={1}>
      <Stack direction={'row'} px={2} py={1} justifyContent={'space-between'}>
        <Stack justifyContent={'center'}>
          <Typography fontWeight={'bold'}>Nouvelle conversation</Typography>
        </Stack>

        <Stack direction={'row'} spacing={1}>
          <IconButton onClick={goToHome}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>

      <Divider />

      <Box flexGrow={1} height={'77.9%'}>
        <Scrollbar>
          <Stack hidden={!isGroup}>
            <Stack justifyContent={'space-between'} px={3} py={2}>
              <TextField
                size={'small'}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                fullWidth
                autoFocus
                variant="standard"
                placeholder={'Titre de la conversation ...'}
                InputProps={{
                  disableUnderline: true
                }}
              />
            </Stack>

            <Divider />
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} px={3} pt={1}>
            <Stack justifyContent={'center'}>
              <Typography>Participants ({participants.length})</Typography>
            </Stack>
            <IconButton ref={ref} size={'small'} onClick={handleOpen}>
              <Add />
            </IconButton>
          </Stack>

          <List dense>
            {participants.map((e) => (
              <UserItem key={e.id} user={e} onRemove={handleRemove} />
            ))}
          </List>
        </Scrollbar>
      </Box>

      <Stack
        onClick={handleCreate}
        height={65}
        alignSelf={'end'}
        justifyContent={'center'}
        sx={{
          bgcolor: (t) => (isValid ? t.palette.primary.main : t.palette.grey[500]),
          cursor: 'pointer'
        }}
      >
        <Stack direction={'row'} justifyContent={'center'}>
          {loading ? (
            <LoadingView size={{ height: '65px', width: '200px' }} />
          ) : (
            <Typography color={'white'} fontWeight={'bold'}>
              Créer {isGroup ? ' un groupe' : 'une conversation'}
            </Typography>
          )}
        </Stack>
      </Stack>

      <MenuPopover disabledArrow width={400} open={open} onClose={handleClose} anchorEl={ref.current}>
        <SelectCollaborates
          preTitle="Selectionnez les "
          title="participants"
          actionTitle="Ajouter"
          currents={participants}
          onClose={handleClose}
          onChange={(_, res) => setParticipants([...res]?.filter((el) => el?.id !== user?.id))}
        />
      </MenuPopover>
    </Box>
  );
}

export const UserItem = ({ user, onRemove }) => {
  const { open, handleClose, handleOpen } = useToggle();

  const selected = transformUser(user);

  const avatarI = createAvatar(selected?.name);

  const size = 35;

  const handleRemove = () => {
    onRemove(user?.id);
  };

  return (
    <ListItem onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <ListItemButton sx={{ borderRadius: 2 }}>
        <ListItemAvatar>
          <MAvatar
            alt={selected?.name}
            src={selected?.avatar}
            sx={{ width: size, height: size, fontSize: 12 }}
            color={selected?.avatar ? 'default' : avatarI.color}
          >
            {avatarI.name}
          </MAvatar>
        </ListItemAvatar>
        <ListItemText primary={selected?.name} />

        {onRemove && open && (
          <IconButton size={'small'} onClick={handleRemove}>
            <Close />
          </IconButton>
        )}
      </ListItemButton>
    </ListItem>
  );
};
