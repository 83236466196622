import { useMicrosoftMail } from 'src/contexts/MicrosoftMailContext';

import MicrosoftLogin from 'react-microsoft-login';

import { AuthBtn } from 'src/section/mail/MailLogin';
import { Icon } from '@iconify/react';

export default function MailLoginOutlook({ onClose }) {
  const { loginBtnProps, onClickAuthBtn, login } = useMicrosoftMail();

  return (
    <>
      <AuthBtn
        label={'Outlook'}
        onClick={login}
        icon={<Icon icon={'file-icons:microsoft-outlook'} color={'#2069BC'} />}
      />
    </>
  );
}
