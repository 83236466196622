import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { AFFECT_STATUS } from 'src/constants/affectation';
import { Affectation_Setting_Type, Affectation_v4_Type } from 'src/models/Affectation_v4_type';
import { getAffectationSetting } from 'src/redux/slices/affectation_v4';
import { dispatch, useSelector } from 'src/redux/store';

/**
 *
 * @returns {{settings: Affectation_Setting_Type, loading: boolean}}
 */
export function useAffectation_v4Settings() {
  const [loading, setLoading] = useState(false);
  const getSettings = useSelector((state) => state.firestore.ordered['affectation_settings']);

  const settings = useMemo(() => (isEmpty(getSettings) ? null : getSettings?.at(0)), [getSettings]);

  useFirestoreConnect(() => [
    {
      collection: 'affectation',
      doc: 'affectation_settings',
      storeAs: 'affectation_settings'
    }
  ]);

  useEffect(() => {
    setLoading(!isLoaded(getSettings));
    dispatch(getAffectationSetting(settings, !isLoaded(getSettings)));
  }, [getSettings, settings]);

  return { settings, loading };
}

/**
 *
 * @param {{
 *  type: 'toDoIds' | 'toReviewIds' | 'historyIds' , 'archiveIds' , 'trashedIds'
 *  userId: string
 * }} props
 * @returns {{affectations: Array<Affectation_v4_Type>, loading: boolean}}
 */
export function useAffectaion_v4({ type, userId }) {
  const [loading, setLoading] = useState(false);

  const getAffectation = useSelector((state) => state.firestore.ordered[`affectation_v4_${type}`]);

  const affectations = useMemo(() => (isEmpty(getAffectation) ? [] : getAffectation), [getAffectation]);
  //TODO: fix archive affectation 
  useFirestoreConnect(() => [ {
        collection: 'affectation',
        where: [
          ['isDelete', '==', false],
          [type, 'array-contains', userId]
        ],
        orderBy: [['updated.at', 'desc']],
        storeAs: `affectation_v4_${type}`
      }]);

  useEffect(() => {
    setLoading(!isLoaded(getAffectation));
  }, [getAffectation, affectations]);

  //console.log(affectations)

  return { affectations, loading };
}
