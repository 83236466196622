import { Stack, Tooltip } from '@mui/material';
import { Avatar } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { TaskType } from 'src/models/task_m';
import createAvatar from 'src/utils/createAvatar';
import ContactsDialog from 'src/components/ContactsDialog';
import { dispatch } from 'src/redux/store';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import { toSimpleAccessUser } from 'src/helpers/user';


/**
 *
 * @param {{task: TaskType, onUpdate: function, disabled: boolean}} props
 * @returns
 */
export default function TaskAssigne({ task, onUpdate, disabled = false }) {
  const [assignee, setAssigne] = React.useState(task?.assignee || []);
  const { handleOpenAddToProjectConfirmation, currentProject } = useProjectContext();
  const { users: _contacts } = useSelector((state) => state.user);
  const onAssigne = React.useCallback(
    (_assigne) => {
      if (disabled) {
        return;
      }

      setAssigne(_assigne);

      const isNotInTheProject = [];
      _assigne.forEach((_user) => {
        if (!currentProject?.canAccessId.find((_id) => _id === _user.id)) {
          isNotInTheProject.push({ ..._user });
        }
      });

      if (isNotInTheProject.length) {
        handleOpenAddToProjectConfirmation({
          task,
          usersToAdd: isNotInTheProject,
          values: { assignee: _assigne },
          callback: () => { }
        });
        return;
      }

      dispatch(onUpdate(task, { assignee: _assigne }));

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUpdate, task?.id, disabled]
  );

  return (
    <Stack width={1} direction="row" alignItems="center" spacing={0.5}>
      {!!assignee?.length && (
        <Avatar.Group maxCount={3}>
          {assignee.map((user, idx) => (
            <Tooltip title={user?.name} arrow key={user.id + idx}>
              <Avatar src={user.avatar} alt={user.name} style={{ backgroundColor: createAvatar(user.name).color2 }}>
                {createAvatar(user.name).name}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      )}
      <Stack>
        {!disabled && (
          <ContactsDialog
            task={assignee}
            onAssigne={onAssigne}
            assigne={assignee}
          />
        )}
      </Stack>
    </Stack>
  );
}