import { DateTime } from 'luxon';
import { useMemo ,useState} from 'react';

/**
 * @typedef {{ currentDay: boolean, date: Date?, dayShort: string, day: number }}
 */
let weekValue = {
  currentDay: false,
  dayShort: '',
  day: 0,
  date: null
};

const WEEKS = {
  1: weekValue,
  2: weekValue,
  3: weekValue,
  4: weekValue,
  5: weekValue,
  6: weekValue,
  7: weekValue
};


export default function useWeekDays() {
  const [currentWeek, setCurrentWeek] = useState(0);
  const weekDay = DateTime.local().plus({ weeks: currentWeek }).setLocale('fr-FR').weekday;
  const dateActuelle = DateTime.local().plus({ weeks: currentWeek });
  const premierJourSemaine = dateActuelle.startOf('week');

  const nextWeek = () => setCurrentWeek(currentWeek + 1);
  const previousWeek = () => setCurrentWeek(currentWeek - 1);

  const weeks = useMemo(() => {
    let weeksWithCurrentDay = {
      ...WEEKS,
      [weekDay]: {
        ...WEEKS[weekDay],
        currentDay: true
      }
    };

    for (let index = 0; index < 7; index++) {
      const jour = premierJourSemaine.plus({ days: index });
      weeksWithCurrentDay = {
        ...weeksWithCurrentDay,
        [index + 1]: {
          ...weeksWithCurrentDay[index + 1],
          date: jour.toJSDate(),
          day: jour.day,
          dayShort: jour.setLocale('fr').weekdayShort
        }
      };
    }

    return weeksWithCurrentDay;
  }, [weekDay, premierJourSemaine]);

  return { weeks, nextWeek, previousWeek };
}
