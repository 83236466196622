export function playNotificationSound(loop=false){
    if(document.getElementById('sound')){
        document.getElementById('sound').play();
        if(loop){
            document.getElementById('sound').loop = true;
        }
    }

    const onStop = () =>{
        document.getElementById('sound').pause();
    }

    return onStop;
}





function showNotification (title, description){
    const logo = localStorage.getItem('s_logo');
   
  
    //eslint-disable-next-line
    const notification = new Notification(title, {
        body: description,
        icon: logo,
        silence:false,
        sound: '/static/alarm/alarm_message.mp3',
        vibrate: [200, 100, 200, 100, 200, 100, 200],
        lang:'fr',
        tag: title
    });

    notification.onclick = () =>{
        if(document.getElementById('sound')){
            document.getElementById('sound').pause();
        }
    }
    notification.onclose = () =>{
        if(document.getElementById('sound')){
            document.getElementById('sound').pause();
        }
    }
}

export const navigatorNotif = (titre, description ) => {
   
    if(Notification.permission === "granted"){
        showNotification(titre, description);
    } else if ( Notification.permission !== 'denied'){
        Notification.requestPermission().then(permission =>{
            if(permission === "granted"){
                showNotification(titre, description);
            }
        });
    }
}