// import useAuth from "./useAuth";
import {useSelector, useDispatch} from "react-redux";
import {useEffect, useState, useMemo} from "react";
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {gDate} from "../utils/formatTime";
import {orderBy} from 'lodash'
import useMessageHistory from "./useMessageHistory";
import useAuth from "./useAuth";
import {markMessageAsRead} from "../redux/slices/conversation";


const messageIdToMark = (list = [], userId) => {

    return list?.filter(el => {
        return el?.senderId !== userId && !el?.timeLine?.find(e => e?.userId === userId)
    })?.map(el => el?.id)

}

export default function useMessages(conversationId) {
    const {user} = useAuth();
    const dispatch = useDispatch();

    const ref = `conversations_messages_${conversationId}`;
    const [loading, setLoading] = useState(false);
    const history = useMessageHistory(conversationId)
    const data = useSelector(state => state.firestore.ordered[ref])
    const list = useMemo(() => {
        return (data || [])?.map(el => ({...el, createdAt: gDate(el?.createdAt || new Date())}))?.map(el => {
            const timeLine = history.list?.find(h => h?.messageId === el?.id)?.timeLine || [];
            const receivedTimeLine = history?.receivedTimeLine?.find(h => h?.messageId === el?.id)?.timeLine || [];
            return {
                ...el,
                timeLine,
                receivedTimeLine
            }
        })
    }, [data, history.list, history?.receivedTimeLine])

    const toMark = useMemo(() => messageIdToMark(list, user?.id), [list, user?.id])

    useEffect(() => {
        if (toMark?.length !== 0) {
            dispatch(markMessageAsRead(conversationId, toMark))
        }
    }, [conversationId, dispatch, toMark])


    useEffect(() => setLoading(!isLoaded(data)), [data]);

    useFirestoreConnect([
        {
            collection: `conversations/${conversationId}/messages`,
            orderBy: ['createdAt', "desc"],
            limit: 50,
            storeAs: ref,
        }
    ])

    return {list: orderBy(list, 'createdAt', 'asc'), loading};
}