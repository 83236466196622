import {useState, useMemo} from 'react';
import {useToggleV2} from "./useToggle";


export default function useAnimateNumbers({start = 0, end = 100, durationMillisecond = 5000}) {
    const [usedStep, setStep] = useState(0)
    const [animating, begin, stop] = useToggleV2()

    const steps = useMemo(() => {
        if (durationMillisecond === 0) return 0;
        return Math.abs(end - start)
    }, [durationMillisecond, start, end])

    const stepsDuration = useMemo(() => {
        return Number((durationMillisecond / steps).toFixed(0))
    }, [durationMillisecond, steps])

    const value = useMemo(()=>{
        if(end>=start){
            return start+usedStep
        }else {
            return start-usedStep
        }
    },[end, start, usedStep])


    const exe = async () => {
        await setTimeout(() => {
            setStep(prevState => {

                if (prevState+1 >= steps) {
                    stop()
                    reset()

                } else {
                    exe()

                }
                return prevState + 1;
            })
        }, stepsDuration)
    }

    const forward = async () => {

        if (!animating) {
            begin()
            await exe()
        }
    }

    const reset = () => {
        setStep(0)
    }


    return {forward, reset, animating,value, usedStep,steps, stepsDuration}
}