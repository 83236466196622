import MuiPalette from './palette';

export const palette = {
  ...MuiPalette.light,
  primary: {
    solidBg: '#FFC107',
    solidBorder: '#FFC107',
    solidHoverBg: '#1939B7',
    solidHoverBorder: '#1939B7',
    solidActiveBg: '#091A7A',
    solidActiveBorder: '#091A7A',
    solidDisabledBg: '#84A9FF',
    solidDisabledBorder: '#84A9FF',
    softColor: '#FFF',
    softBg: '#FFC107',
    softBorder: '#FFC107',
    softHoverBg: '#1939B7',
    softHoverBorder: '#1939B7',
    softActiveBg: '#091A7A',
    softActiveBorder: '#091A7A',
    softDisabledBg: '#84A9FF',
    softDisabledBorder: '#84A9FF'
  },
  neutral: {
    solidBg: '#6c757d',
    solidBorder: '#6c757d',
    solidHoverBg: '#5c636a',
    solidHoverBorder: '#565e64',
    solidActiveBg: '#565e64',
    solidActiveBorder: '#51585e',
    solidDisabledBg: '#6c757d',
    solidDisabledBorder: '#6c757d',
    softColor: '#000',
    softBg: '#f8f9fa',
    softBorder: '#f8f9fa',
    softHoverBg: '#f9fafb',
    softHoverBorder: '#f9fafb',
    softActiveBg: '#f9fafb',
    softActiveBorder: '#f9fafb',
    softDisabledBg: '#f8f9fa',
    softDisabledBorder: '#f8f9fa'
  },
  success: {
    solidBg: '#198754',
    solidBorder: '#198754',
    solidHoverBg: '#157347',
    solidHoverBorder: '#146c43',
    solidActiveBg: '#146c43',
    solidActiveBorder: '#13653f',
    solidDisabledBg: '#198754',
    solidDisabledBorder: '#198754'
  },
  danger: {
    solidBg: '#dc3545',
    solidBorder: '#dc3545',
    solidHoverBg: '#bb2d3b',
    solidHoverBorder: '#b02a37',
    solidActiveBg: '#b02a37',
    solidActiveBorder: '#a52834',
    solidDisabledBg: '#dc3545',
    solidDisabledBorder: '#dc3545'
  },
  warning: {
    solidColor: '#000',
    solidBg: '#FDC23A',
    solidBorder: '#FDC23A',
    solidHoverBg: '#B67E1D',
    solidHoverBorder: '#B67E1D',
    solidActiveBg: '#794A0B',
    solidActiveBorder: '#794A0B',
    solidDisabledBg: '#FEF7D7',
    solidDisabledBorder: '#FEF7D7'
  },
  info: {
    solidColor: '#FFF',
    solidBg: '#1890FF',
    solidBorder: '#1890FF',
    solidHoverBg: '#478408',
    solidHoverBorder: '#478408',
    solidActiveBg: '#275803',
    solidActiveBorder: '#275803',
    solidDisabledBg: '#C0E969',
    solidDisabledBorder: '#C0E969',
    softColor: '#FFF',
    softBg: '#1890FF',
    softBorder: '#1890FF',
    softHoverBg: '#478408',
    softHoverBorder: '#478408',
    softActiveBg: '#275803',
    softActiveBorder: '#275803',
    softDisabledBg: '#C0E969',
    softDisabledBorder: '#C0E969'
  },
  darker: {
    solidColor: '#fff',
    solidBg: '#212121',
    solidBorder: '#212121',
    outlinedBorder: '#212121',
    solidHoverBg: '#191516',
    solidHoverBorder: '#191516',
    solidActiveBg: '#0E0508',
    solidActiveBorder: '#0E0508',
    solidDisabledBg: '#E8E8E8',
    solidDisabledBorder: '#E8E8E8'
  }
};

const baseFont = {
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  fontWeight: 400
};

const themeOption = {
  colorSchemes: {
    light: { palette },
    dark: { palette }
  },
  typography: {
    h1: {
      ...baseFont
    },
    h2: {
      ...baseFont
    },
    h3: {
      ...baseFont
    },
    h4: {
      ...baseFont
    },
    h5: {
      ...baseFont
    },
    body1: {
      ...baseFont
    },
    body2: {
      ...baseFont
    },
    body3: {
      ...baseFont
    },
    body4: {
      ...baseFont
    },
    body5: {
      ...baseFont
    }
  },
  components: {
    JoyButton: {
      defaultProps: {
        size: 'md'
      },
      styleOverrides: {}
    },
    JoyListItemContent: {
      styleOverrides: {
        root: {
          fontFamily: ['Montserrat', 'sans-serif'].join(',')
        }
      }
    }
  }
};

export default themeOption;
