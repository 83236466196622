import React from 'react';
import { Button, Stack } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useMailContext } from 'src/section/mail/MailContext';
import { gfDateTime } from 'src/utils/formatTime';

const CustomButton = ({ startIcon='mdi:email-outline', onClick=null, children=null }) => {
  return (
    <Button
      color={'inherit'}
      size={'small'}
      sx={{ bgcolor: '#ececec', ':hover': { bgcolor: '#d4d4d4' }, px: 2 }}
      startIcon={<Iconify icon={startIcon} sx={{ color: 'dark' }} />}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};


const  MailDetailAnswerBox = ({ message=null, onReset=null , isModal=false, onCloseModal=null, modalRef=null}) => {
  const { handleChangeMailComposerMulti, openComposer, account, setAccount,setIsNewDraft } = useMailContext();

  // console.log('message', message);

  const handleMailComposer = async ({ to = [], replyTo = [], cc = [], cci = [], body = '' }) => {
    isModal && onCloseModal();
    setIsNewDraft(true);
    await handleChangeMailComposerMulti({
      to,
      replyTo,
      cc,
      cci,
      subject: `Re: ${message?.subject}`,
      body
    });
    setAccount(message?.account);
    openComposer();
  };

  const onAnswer = () => handleMailComposer({ to: [message?.from?.email], replyTo: [message?.from?.email] });

  const onAnswerAll = () => handleMailComposer({
    to: [message?.from?.email, ...(message?.to ? message?.to?.map((item) => item.email) : [])],
    replyTo: [message?.from?.email, ...(message?.to ? message?.to?.map((item) => item.email) : [])],
    cc: [...(message?.cc ? message?.cc?.map((item) => item?.email) : [])],
    cci: [...(message?.cci ? message?.cci?.map((item) => item?.email) : [])]
  });

  const onTransfer = () => handleMailComposer({ body: getResponsePart() });

  const getResponsePart = () => {
    return `
            <br/>
            <br/>
            Le ${gfDateTime(message?.sentDate)}, ${message?.from?.name} < ${message?.from?.email} > a écrit :
            <br/>
              
            ${message?.body}
           
            `;
  };

  return (
    <Stack
      p={2}
      width={1}
      height={'fit-content'}
      bgcolor={'white'}
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderColor: (t) => t.palette.divider
      }}
    >
      <Stack direction={'row'} spacing={2}>

        <CustomButton
         
          startIcon='mdi:email-outline'
          onClick={onAnswer}
        >
          Répondre
        </CustomButton>
        <CustomButton
        
          startIcon='mdi:email-edit-outline'
          onClick={onAnswerAll}
        >
          Répondre à tous
        </CustomButton>
        <CustomButton
         
          startIcon='mdi:email-plus-outline'
          onClick={onTransfer}
        >
          Transférer
        </CustomButton>
      </Stack>
    </Stack>
  );
}

export default MailDetailAnswerBox;