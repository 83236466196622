export const RAPPEL_TYPE = {
    AFFECTATION: 'affectation'
}

export const RAPPEL_KIND = {
    UNIQUE: "Unique",
    MULTIPLE: "Multiple",
    LOOP: "Répétitif"
}

export const RAPPEL_FREQUENCY_RYTHM = {
    DAY: "Jour",
    WEEK: "Semaine",
    MONTH: "Mois"
}

