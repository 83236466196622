import { useRef, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
    PdfViewerComponent, Toolbar, Magnification,
    Navigation, LinkAnnotation, BookmarkView, ThumbnailView,
    Print, TextSelection, TextSearch, Annotation,
    FormFields, FormDesigner, Inject
} from '@syncfusion/ej2-react-pdfviewer';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import { Stack, IconButton, Backdrop, CircularProgress, Tooltip } from '@mui/material';
import { L10n } from '@syncfusion/ej2-base';
import { defautL10nLocale } from './../constants/defaultL10nLocale';
import { saveFile } from 'src/redux/slices/document';
import CircularProgressWithLabel from './CircularProgressWithLabel';

L10n.load({
    fr: defautL10nLocale
});


export default function PdfFileEditor({ file, onClose, handleUpdate, email }) {
    const viewer = useRef(null);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);



    useEffect(() => {
        if (viewer.current && file) {
            setLoading(true);
            const xhr = new XMLHttpRequest()
            xhr.responseType = 'blob';
            xhr.onload = (e) => {
                const myBlob = xhr.response;
                const reader = new FileReader();
                reader.readAsDataURL(myBlob)
                reader.onloadend = () => {
                    const base64data = reader.result;
                    viewer.current.documentPath = base64data;
                    setLoading(false);
                }

                reader.onerror = () => {
                    setLoading(false);
                }
            }
            xhr.onerror = (e) => {
                setLoading(false);
            };
            xhr.open('GET', file?.url);
            xhr.send();
        }
    }, [file]);

    const onFileSave = () => {
        if (viewer.current) {
            setLoading(true);
            viewer.current.saveAsBlob('pdf').then((blob) => {
                let exportedDoc = blob;
                //console.log('viewer f', exportedDoc);
                const _file = new File([exportedDoc], file?.name, { type: file?.type });

                saveFile(_file, handleUpdate, () => {
                    setLoading(false);
                    onClose();
                    enqueueSnackbar('Document enrégistré avec succès', { variant: 'success' });
                }, setUploading, file?.id)
                    .catch(() => {
                        //console.log('failed');
                    });
            })
        }
    }


    return (
        <div>
            <Backdrop open={loading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 150 }}>
                {!uploading ? <CircularProgress color="inherit" />
                    :
                    <CircularProgressWithLabel size={70} color='inherit' value={uploading?.progress || 0} />}
            </Backdrop>

            <Stack sx={{ bgcolor: '#0A3D7A' }} justifyContent="flex-end" direction="row" width={1}>
                <Stack direction="row" spacing={3} sx={{ py: 0.5, px: 2 }}>
                    <Stack direction="row" height={30} width={25}>
                        <Tooltip title="Enregistrer">
                            <IconButton size="small" color="default" onClick={onFileSave}>
                                <Icon color="white" icon={saveFill} width={22} height={22} />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Stack direction="row" height={30} width={25}>
                        <Tooltip title="Fermer">
                            <IconButton size="small" color="inherit" onClick={onClose}>
                                <Icon color="white" icon={closeFill} width={22} height={22} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>

            <PdfViewerComponent
                ref={viewer}
                id="containerpdfviewer"
                enableToolbar={true}
                serviceUrl='https://ej2services.syncfusion.com/production/web-services/api/pdfviewer'
                locale='fr'
                annotationSettings={{
                    author: email
                }}
                style={{ height: `${window.innerHeight - 50}px` }}
            >
                <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation,
                    BookmarkView, ThumbnailView, Print, TextSelection,
                    TextSearch, Annotation, FormFields, FormDesigner]} />
            </PdfViewerComponent>
        </div>
    );
}
