import { createContext, useContext, useState } from 'react';
import { getFileFormat, getFilename } from '../../utils/getFileFormat';
import { capitalize } from '../../utils/format_text';

const MessageResponseContext = createContext({
  response: null,
  lookedUpMessageId: null,
  setMessageToRespond: (message) => {},
  cleanup: () => {},
  cleanLookup: () => {},
  registerAnchor: (anchorsId, ref) => {},
  scrollToMessage: (messageId) => {}
});

export const useConverseMessageResponse = () => useContext(MessageResponseContext);

const getMessageResume = (message) => {
  if ((message?.body || '').trim().length !== 0) {
    return message?.body;
  }

  if (message?.audio) {
    return 'Note vocale';
  }

  const attachments = message?.attachments || [];

  if (attachments?.length >= 2) {
    return `${message?.attachments?.length} x Fichiers attachés`;
  }

  if (attachments?.length === 1) {
    const url = attachments[0]?.url;
    const filename = getFilename(url);
    const format = getFileFormat(filename);

    return capitalize(format);
  }

  return '';
};

export default function ConverseMessageResponseProvider({ children }) {
  const [lookedUpMessageId, setLookedUpMessageId] = useState(null);
  const [response, setResponse] = useState(null);
  const [anchors, setAnchors] = useState({});

  const setMessageToRespond = (message) => {
    const ref = {
      messageId: message?.id,
      senderId: message?.senderId,
      resume: getMessageResume(message),
      preview: message?.attachments[0]?.url || null
    };
    setResponse(ref);
  };

  const registerAnchor = (anchorsId, ref) => {
    setAnchors((prevState) => {
      return { ...prevState, [anchorsId]: ref };
    });
  };
  const scrollToMessage = (messageId) => {
    const ref = anchors[messageId];
    if (ref) {
      setLookedUpMessageId(messageId);
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const cleanLookup = () => {
    setLookedUpMessageId(null);
  };

  const cleanup = () => {
    setResponse(null);
  };

  const store = {
    response,
    lookedUpMessageId,
    setMessageToRespond,
    cleanup,
    cleanLookup,
    registerAnchor,
    scrollToMessage
  };

  return <MessageResponseContext.Provider value={store}>{children}</MessageResponseContext.Provider>;
}
