import useAuth from 'src/hooks/useAuth';
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector, useDispatch} from "react-redux";
import {useEffect, useState, useMemo} from "react";
import {orderBy} from 'lodash'
import {
    areHistoryLoading,
    genHistoryQueries,
    getConversationUnreadCount, getConversationUnreadIds, getUnmarkedReceivedMessageId,
    transformHistory
} from "../helpers/conversations";
import {markMessageAsReceived} from "../redux/slices/conversation";

export const transformUser = val => {
    //
    return ({
        id: val?.id,
        name: val?.name || val?.displayName || val?.lastName + ' ' + val?.firstName || '',
        avatar: val?.avatar || val?.photoUrl || val?.photoURL || null,
        email: val?.email || null,
        last_changed: val?.last_changed || null
    });
}

const transformConversation = (record = [], userId) => {
    //
    return orderBy(record.map(el => {
        let name = '';
        const participants = (el?.participants || []).map(el => transformUser(el));

        if (participants?.length !== 2) {
            participants.forEach((e, idx) => {
                if (e?.id !== userId)
                    name += `${e.name}${idx + 1 === participants.length ? "" : ","} `
            })

            if (name[name.length - 2] === ',') {
                name = name.substring(0, name.length - 2);
            }

            return {
                ...el,
                name: el?.name || name,
                participants
            }
        }

        const theOther = participants?.filter(el => el?.id !== userId)[0];

        return {
            ...el,
            name: theOther?.name || el?.name || name,
            avatar: theOther?.avatar || null,
            participants
        }

    }), 'lastSend', 'desc')
}

const ref = "conversations";

export default function useConversation() {
    const {user} = useAuth();
    const store = useSelector(state => state.firestore.ordered)
    const data = useSelector(state => state.firestore.ordered[ref])
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const list = useMemo(() => transformConversation(data, user?.id), [data, user?.id])

    const historyQueries = useMemo(() => genHistoryQueries(list?.map(el => el?.id)), [list])
    const historyLoading = useMemo(() => areHistoryLoading(store, list?.map(el => el?.id)), [store, list])
    const conversation = useMemo(() => {
        return list?.map(el => {
            const readTimeLine = transformHistory(store, el?.id) || [];
            const unreadCount = getConversationUnreadCount(readTimeLine, el?.id, user?.id);
            const unreadIds = getConversationUnreadIds(readTimeLine, el?.id, user?.id);
            const receivedTimeLine = transformHistory(store, el?.id, 'received') || [];
            const unmarkedReceivedIds = getUnmarkedReceivedMessageId(unreadIds, el?.id, receivedTimeLine);
            return {
                ...el,
                readTimeLine,
                receivedTimeLine,
                unreadCount,
                unreadIds,
                unmarkedReceivedIds
            }
        })
    }, [list, store, user?.id])


    const findByParticipants = participants => {
        const ids = participants?.map(el => el?.id);

        return conversation?.filter(el => el?.participants?.length === participants?.length)?.filter(el => {
            return el?.participants?.filter(re => !ids?.includes(re?.id))?.length === 0;
        });
    }
    // console.log(conversation)

    const unreadCount = useMemo(() => {
        let count = 0;
        conversation?.forEach(el => {
            count += el?.unreadCount || 0
        })
        return count
    }, [conversation])

    const unreadIds = useMemo(() => {
        let count = [];
        conversation?.forEach(el => {
            if (el?.unreadIds?.length !== 0)
                count = [...count, {
                    conversationId: el?.id,
                    unreadIds: el?.unreadIds
                }]
        })
        return count
    }, [conversation])

    const unmarkedReceivedIds = useMemo(() => {
        let count = [];
        conversation?.forEach(el => {
            if (el?.unreadIds?.length !== 0)
                count = [...count, ...el?.unmarkedReceivedIds]
        })
        return count
    }, [conversation])


    useEffect(() => {


        unreadIds?.forEach(el => {
            dispatch(markMessageAsReceived(el?.conversationId, el?.unreadIds, unmarkedReceivedIds))
        })

    }, [unreadIds, dispatch, unmarkedReceivedIds])

    useEffect(() => {
        if (!isLoaded(data))
            return setLoading(false);
        if (isLoaded(data) && conversation?.length !== 0)
            return setLoading(historyLoading)
    }, [conversation?.length, data, historyLoading]);

    useFirestoreConnect([
        {
            collection: `conversations`,
            orderBy: ['createdAt', "desc"],
            where: [["participantsId", 'array-contains', user?.id]],
            storeAs: ref,
        },
        ...historyQueries
    ])

    return {list: conversation, unreadCount, unreadIds, loading, findByParticipants}
}