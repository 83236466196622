import { Stack, Button ,MenuItem, Select,Typography,IconButton,Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import Iconify from 'src/components/Iconify';
import { MenuLine } from 'src/components/MenuLine';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import { V4_DATE_STATE, V4_TASK_STATE, V4_TASK_STATE_CONVERT,V4_ONLY_MY_TASK } from 'src/constants/task';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useV4ProjectContext } from '.';

const FILTER_FIELD = {
  state: 'state',
  date: 'date',
  onlyMyTask: 'onlyMytask'
};

const onlyMyTaskDescription = {
  [V4_ONLY_MY_TASK.ALL]: 'Toutes les tâches',
  [V4_ONLY_MY_TASK.CREATED]: 'Mes tâches créées ',
  [V4_ONLY_MY_TASK.ASSIGNEE]: 'Mes tâches assignées '
};  

export default function V4taskFilters() {
  const { taskFilters, handleChangeFilter } = useV4ProjectContext();

  return (
    <Stack direction="row" spacing={2}>
      <TaskStateFilter state={taskFilters.state} onStateChange={(val) => handleChangeFilter(FILTER_FIELD.state, val)} />
      <TaskAssignFilter
        state={taskFilters.onlyMytask}
        handleChangeFilter={handleChangeFilter}
      />
      <TaskDateFilter state={taskFilters.date} onStateChange={(val) => handleChangeFilter(FILTER_FIELD.date, val)} />
    </Stack>
  );
}

const TaskStateFilter = ({ state, onStateChange }) => {
  const [open, onOpen, onClose] = useToggleV2();
  const anchorREf = useRef();

  return (
    <>
      <Button
        size="small"
        color="inherit"
        onClick={onOpen}
        ref={anchorREf}
        startIcon={<Iconify icon="mingcute:check-circle-line" sx={{ height: 15, width: 15 }} />}
      >
        {V4_TASK_STATE_CONVERT[state]}
      </Button>
      {open && (
        <CustomMenuPopover
          open={open}
          onClose={onClose}
          anchorEl={anchorREf.current}
          disabledArrow
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {Object.entries(V4_TASK_STATE).map(([key, val]) => (
            <MenuLine
              key={val}
              {...(val === state && { icon: 'ic:round-done' })}
              title={V4_TASK_STATE_CONVERT[val]}
              onClick={() => onStateChange(key)}
            />
          ))}
        </CustomMenuPopover>
      )}
    </>
  );
};

const TaskDateFilter = ({ state, onStateChange }) => {
  const [open, onOpen, onClose] = useToggleV2();
  const anchorREf = useRef();

  return (
    <>
      <Button
        size="small"
        color="inherit"
        onClick={onOpen}
        ref={anchorREf}
        startIcon={<Iconify icon="material-symbols:filter-list-rounded" sx={{ height: 15, width: 15 }} />}
      >
        {state || 'Filtrer'}
      </Button>
      {open && (
        <CustomMenuPopover
          open={open}
          onClose={onClose}
          anchorEl={anchorREf.current}
          disabledArrow
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {Object.entries(V4_DATE_STATE).map(([_, val]) => (
            <MenuLine
              key={val}
              {...(val === state && { icon: 'ic:round-done' })}
              title={val}
              onClick={() => onStateChange(state === val ? null : val)}
            />
          ))}
        </CustomMenuPopover>
      )}
    </>
  );
};

const TaskAssignFilter = ({ handleChangeFilter , state}) => {
  const [open, onOpen, onClose] = useToggleV2();
  const anchorREf = useRef();
  const onStateChange = (newState) => {
    handleChangeFilter(FILTER_FIELD.onlyMyTask,newState);
  };

  const openMenu = (event) => {
    event.stopPropagation();
    onOpen();
  }

  return (
    <>
      <Button
        size="small"
        color="inherit"
        variant={state!==V4_ONLY_MY_TASK.ALL  ? 'outlined' : 'text'}
        ref={anchorREf}
        onClick={onOpen}
        startIcon={
          <Iconify
            icon={state!==V4_ONLY_MY_TASK.ALL ? 'material-symbols:person-sharp' : 'material-symbols:person-off-outline-sharp'}
            sx={{ height: 15, width: 15 }}
          />
        }
        endIcon={
          <IconButton size="small" onClick={(event) => {openMenu(event)}} >
            <Iconify icon="mdi:chevron-down" sx={{ height: 15, width: 15 }} />
          </IconButton>}
      >
        {onlyMyTaskDescription[state] || 'Mes tâches uniquement'}
      </Button>
      <CustomMenuPopover
        open={open}
        onClose={onClose}
        anchorEl={anchorREf.current}
        disabledArrow
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {Object.entries(V4_ONLY_MY_TASK).map(([_, val]) => (
          <MenuLine
            key={val}
            {...(val === state && { icon: 'ic:round-done' })}
            title={onlyMyTaskDescription[val]}
            onClick={() => onStateChange(val)}
            />
        ))}
      </CustomMenuPopover>
    </>
  );
};