import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/messaging';
import { firebaseConfig } from '../config';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { dispatch as storeDispatch } from 'src/redux/store';
import { updateUser } from '../redux/slices/user';
import { MULTI_SITES } from 'src/redux/slices/site';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from './../routes/paths';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  firebase.storage();

  if (firebase.messaging.isSupported()) {
    firebase.messaging();
  }
  firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    merge: true
  });

  firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
      if (err.code === 'failed-precondition') {
        //console.log('failed precondition');
      } else if (err.code === 'unimplemented') {
        //console.log('unimplemented');
      }
    });
}

const firestore = firebase.firestore();
const storage = firebase.storage();
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
const auth = firebase.auth();
const firebasedb = firebase.database();

const defaultDb = firebase;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  /**
   * @type {firebase.UserInfo}
   */
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const initDbState = {
  db: firebase
};
const projectInit = (state, action) => {
  if (action.type === MULTI_SITES.DIWA_PRODUCTS) {
    return {
      ...state,
      db: action.payload
    };
  }

  return {
    ...state,
    db: firebase
  };
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  profileLoading: false,
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updatePassword: (oldPass, newPass) => Promise.resolve(),
  updateLocalProfile: (field, value) => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dbstate] = useReducer(projectInit, initDbState);
  const auth = { ...state.user };
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();

  const rrfProps = {
    firebase,
    config: {
      userProfile: 'users',
      useFirestoreForProfile: true
    },
    dispatch: storeDispatch,
    createFirestoreInstance
  };

  //#region FIREBASE USER PRESENCE
  const handleUserPresence = useCallback(() => {
    if (auth?.uid) {
      //REAL TIME DATABASE
      let userStatusDatabaseRef = firebase.database().ref('/status/' + auth.uid);

      let isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP
      };
      let isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP
      };

      // FIRESTORE
      var userStatusFirestoreRef = firebase.firestore().doc('/users/' + auth.uid);

      var isOfflineForFirestore = {
        state: 'offline',
        last_changed: firebase.firestore.FieldValue.serverTimestamp()
      };

      var isOnlineForFirestore = {
        state: 'online',
        last_changed: firebase.firestore.FieldValue.serverTimestamp()
      };

      // REALTIME AND FIRESTORE DATABASE LISTENER
      firebase
        .database()
        .ref('.info/connected')
        .on('value', (snapshot) => {
          if (snapshot.val() === false) {
            userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
            return;
          }

          userStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(() => {
              userStatusDatabaseRef.set(isOnlineForDatabase);
              userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true });
            });
        });
    }
  }, [auth?.uid]);

  const handleUserPresenceOfflineOnLogout = (uid) => {
    let userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

    let isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP
    };

    var userStatusFirestoreRef = firebase.firestore().doc('/users/' + uid);

    var isOfflineForFirestore = {
      state: 'offline',
      last_changed: firebase.firestore.FieldValue.serverTimestamp()
    };

    userStatusDatabaseRef.set(isOfflineForDatabase);
    userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
  };
  //#endregion

  //#region EFFECT NOTIFICATION AND PLATFORM TYPE
  useEffect(
    () =>
      dbstate.db.auth().onAuthStateChanged((user) => {
        if (user) {
          const docRef = dbstate.db.firestore().collection('users').doc(user.uid);
          docRef.onSnapshot((doc) => {
            if (doc.exists && isMountedRef.current) {
              setProfile({ id: doc.id, ...doc.data() });
              setProfileLoading(false);
            }
          });

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user }
          });
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

          docRef.update({ plateform: isMobile ? 'Mobile' : 'Bureau' });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),

    [dispatch, dbstate.db, isMountedRef]
  );

  useEffect(() => {
    if (auth?.uid) {
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        messaging
          .getToken()
          .then((response) => {

            if (response) {
              firebase.firestore().collection('users').doc(auth?.uid).update({ token: response });
            } else {
              Notification.requestPermission().then((e) => {
                if (e === 'granted') {
                  messaging.getToken().then((response) => {
                    if (response) {
                      firebase.firestore().collection('users').doc(auth?.uid).update({ token: response });
                    }
                  });
                }
              });
            }
          })
          .catch((err) => {
            //console.log('An error occurred while retrieving token. ', err);
            Notification.requestPermission()
              .then(function (p) {
                if (p === 'granted') {
                  if (firebase.messaging.isSupported()) {
                    messaging.getToken().then((response) => {
                      if (response) {
                        firebase.firestore().collection('users').doc(auth?.uid).update({ token: response });
                      } else {
                        // Show permission request UI
                        //console.log('No registration token available. Request permission to generate one.');
                      }
                    });
                  }
                } else {
                  //console.log('User blocked notifications.');
                }
              })
              .catch(function (err) {
                console.error(err);
              });
          });
      }
    }
  }, [auth?.uid]);

  useEffect(() => {
    handleUserPresence();
  }, [handleUserPresence]);

  //#endregion

  const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const register = (email, password, firstName, lastName) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`
          });
      });

  const logout = async () => {
    const _auth = { ...auth };
    await firebase
      .auth()
      .signOut()
      .then(() => {
        navigate(PATH_AUTH.login);
      });
    handleUserPresenceOfflineOnLogout(_auth?.uid);
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = async (user, callback) => {
    storeDispatch(updateUser({ user, callback }));
  };

  const updatePassword = async (oldPass, newPass) => {
    return new Promise(async (res, rej) => {
      try {
        const cred = firebase.auth.EmailAuthProvider.credential(auth.email, oldPass);
        await firebase.auth().currentUser.reauthenticateWithCredential(cred);
        res(await firebase.auth().currentUser.updatePassword(newPass));
      } catch (error) {
        rej(error);
      }
    });
  };

  const anonyMousAuthUser = async () => {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: true, user: null }
        });
      })
      .catch((reason) => console.log(reason));
  };

  const updateLocalProfile = (field, value) => {
    setProfile((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          ...profile,
          online: profile?.state || false,
          id: auth.uid,
          uid: auth.uid,
          email: auth.email || null,
          photoURL: auth.photoURL || profile?.photoUrl || null,
          displayName: auth.displayName || profile?.lastName + ' ' + profile?.firstName || '',
          role: profile?.roles || profile?.role || {},
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          poste: profile?.poste || '',
          address: profile?.address || '',
          active: profile?.active || false,
          responsables: profile?.responsables || [],
          state: profile?.state || false,
          token: profile?.token || null,
          microsoftCredential: profile?.microsoftCredential || null,
          linkedAccount: profile?.linkedAccount || {}
        },
        profileLoading,
        login,
        register,
        logout,
        resetPassword,
        updateProfile,
        updatePassword,
        updateLocalProfile
      }}
    >
      <ReactReduxFirebaseProvider {...rrfProps}>{children}</ReactReduxFirebaseProvider>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider, firestore, storage, auth, firebasedb, messaging, defaultDb };
