import { Box, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import ConverseConversations from './ConverseConversations';
import { CONVERSE_VIEW, useConverseProvider } from './ConverseProvider';
import ConversationCreate from './ConversationCreate';
import ConverseChatRoom from './ConverseChatRoom';
import ConverseDetails from './ConverseDetails';

ConverseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.any.isRequired
};

export default function ConverseModal({ open, onClose, anchor }) {
  const { view, setView } = useConverseProvider();

  const handleClose = () => {
    onClose();
    setView(CONVERSE_VIEW.CONVERSATION_LIST);
  };

  return (
    <Popover
      open={open}
      disablePortal
      anchorEl={anchor.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box height={550} width={400}>
        {view === CONVERSE_VIEW.CONVERSATION_LIST && <ConverseConversations />}

        {view === CONVERSE_VIEW.CREATE_FORM && <ConversationCreate />}

        {view === CONVERSE_VIEW.CHATROOM && <ConverseChatRoom onClose={handleClose} />}
        {view === CONVERSE_VIEW.DETAILS && <ConverseDetails onClose={handleClose} />}
      </Box>
    </Popover>
  );
}
