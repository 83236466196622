import { Icon } from '@iconify/react';
import { Button, Paper, Stack } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useV4ProjectContext } from '..';
import V4ProjectCotentKanbanCard, { V4ProjectContentKanbanNewCard } from './V4ProjectCotentKanbanCard';
import { Droppable } from 'react-beautiful-dnd';
import Scrollbar from 'src/components/Scrollbar';
import { ribbon } from '@syncfusion/ej2-react-spreadsheet';

export default function V4ProjectCotentKanbanBody({ column, onOpenTasknew, openTasknew, onCloseTasknew , rights}) {
  const scrollRef = useRef();
  const {
    board: { cards }
  } = useV4ProjectContext();

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    if (openTasknew) {
      scrollMessagesToBottom();
    }
  }, [openTasknew]);

  const handleOpen = () => {
    onOpenTasknew();
  };

  const cardIds = useMemo(() => {
    return [...(column?.cardIds || [])].filter((cardId) => cards[cardId]);
  }, [cards, column?.cardIds]);



  const canCreate = rights?.create;
  

  return (
    <Stack height="calc(100vh - 150px)">
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
        <Droppable droppableId={column?.id} type="task" >
          {(provided, snapshot) => (
            <Stack
              {...provided.droppableProps}
              ref={provided.innerRef}
              component={Paper}
              height="calc(100vh - 150px)"
              spacing={1}
              sx={{
                p: 0.5,
                transition: 'background-color 0.3s ease',
                ...(snapshot.isDraggingOver
                  ? { bgcolor: 'rgba(233, 255, 90, 0.2)' }
                  : cardIds?.length && { bgcolor: 'transparent' })
              }}
            >
              <V4ProjectContentKanbanCardList cardIds={cardIds} cards={cards} />

              {provided.placeholder}

              {openTasknew && (
                <V4ProjectContentKanbanNewCard operation={column} open={openTasknew} onClose={onCloseTasknew} rigths={rights} />
              )}
              <Button
                fullWidth
                color="inherit"
                startIcon={<Icon icon="eva:plus-fill" height={15} width={15} />}
                onClick={canCreate ? handleOpen : () => {}}
                disabled={!canCreate}
              >
                Ajouter une tâche
              </Button>
            </Stack>
          )}
        </Droppable>
      </Scrollbar>
    </Stack>
  );
}

const V4ProjectContentKanbanCardList = ({ cardIds, cards }) => {
  return (
    <Stack spacing={1}>
      {cardIds?.map((cardId, index) => (
        <V4ProjectCotentKanbanCard key={cardId} task={cards[cardId]} index={index}  />
      ))}
    </Stack>
  );
};
