import { IconButton, Stack, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import Iconify from '../Iconify';
import TextMaxLine from '../TextMaxLine';
import { Box, Button } from '@mui/joy';
import { useDropzone } from 'react-dropzone';
import { getFileThumbSecond } from 'src/utils/getFileFormat';
import { nanoid } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { useToggleV2 } from 'src/hooks/useToggle';

/**
 *
 * @param {{
 *  placeholder: string,
 *  multiple:boolean,
 *  maxSize: number,
 *  accept: string,
 *  onChange: (files) => void,
 *  value: Array<any>,
 *  disabled: boolean,
 *  fileView: React.ReactNode
 * }} props
 *
 *
 * @description multiple: if can wrap multiple file not,
 * @description maxSize: file max size,
 * @description accept: type of file,
 * @description onChange: callback when files dorp,
 * @description values: Array of file or Array of file link
 * @description fileView: A Custom view for the file handler
 *
 * @returns
 */
export default function MuiUploadFileInput({
  placeholder,
  multiple = true,
  maxSize,
  accept,
  onChange,
  value,
  disabled,
  fileView
}) {
  const handleDropFile = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        Object.assign(file, {
          id: nanoid(),
          url: URL.createObjectURL(file)
        });
      });
      //console.log({ value, acceptedFiles });
      const mergeFile = uniqBy([...value, ...acceptedFiles], 'id');
      //console.log({ mergeFile });
      onChange(mergeFile);
    },

    [onChange, value]
  );

  const { getInputProps, getRootProps, inputRef } = useDropzone({
    multiple: multiple,
    onDrop: handleDropFile,
    ...(accept && { accept: accept }),
    ...(maxSize && { maxSize: maxSize }),
    ...(disabled && { disabled: disabled })
  });

  const removeFile = (id) => {
    onChange(value.filter((one) => one?.id !== id));
  };

  return (
    <Stack
      {...getRootProps()}
      component={Button}
      px={1}
      width={1}
      spacing={1}
      direction="row"
      variant="plain"
      color="inherit"
      display="flex"
      alignItems="center"
      justifyContent="start"
      {...(fileView && { bgcolor: 'transparent' })}
    >
      <input {...getInputProps()} />

      {!fileView &&
        value.map((file) => {
          return <OneFileView file={file} removeFile={removeFile} />;
        })}

      {fileView || (
        <>
          <Iconify icon="material-symbols:attach-file-add-rounded" sx={{ height: 20, width: 20 }} />
          <TextMaxLine fontSize={10} fontWeight={100} color="grey.500">
            {placeholder}
          </TextMaxLine>
        </>
      )}
    </Stack>
  );
}

const OneFileView = ({ file, removeFile }) => {
  const { name, url, type, id } = file;

  const [hover, onHover, endHover] = useToggleV2();

  return (
    <Box position="relative" onMouseEnter={onHover} onMouseLeave={endHover}>
      <Tooltip key={file.url} title={name} arrow>
        {type?.includes('image') ? (
          <Box
            component="img"
            alt="file preview"
            src={url}
            sx={{
              borderRadius: 1,
              objectFit: 'cover',
              width: 30,
              height: 30
            }}
          />
        ) : (
          <Box>{getFileThumbSecond(type, url, { width: 30, height: 30 })}</Box>
        )}
      </Tooltip>
      {hover && (
        <IconButton
          sx={{
            position: 'absolute',
            top: -10,
            left: 0,
            p: 0,
            bgcolor: '#000',
            ':hover': { bgcolor: '#000' }
          }}
          size="small"
          onClick={() => removeFile(id)}
        >
          <Iconify icon="eva:close-fill" color="#FFF" />
        </IconButton>
      )}
    </Box>
  );
};
