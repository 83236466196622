import React from 'react';
import { MenuLine } from 'src/components/MenuLine';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import { baseColors } from 'src/constants/color';

export default function V4OperationMore({ open, onClose, anchorEl, onEdit, onDelete }) {
  const handleEdit = () => {
    if (onEdit) {
      onEdit();
      onClose();
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
      onClose();
    }
  };

  return (
    <CustomMenuPopover
      open={open}
      disabledArrow
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuLine icon="eva:edit-outline" title="Renommer l'opération" onClick={handleEdit} disabled={!onEdit} />
      <MenuLine icon="eva:trash-outline" title="Supprimer l'opération" color={baseColors.REDD} onClick={handleDelete} disabled={!onDelete} />
    </CustomMenuPopover>
  );
}