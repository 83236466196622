import { useEffect, useState } from "react";

const useAudioRecorder = ({onNoteDone, getBlob}) => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [recordingTime, setRecordingTime] = useState(null);
  const [interval, setIntervalT] = useState(null);

  useEffect(() => {

    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }


    if (isRecording) {
      recorder?.state !== 'recording' && recorder.start();
        const inter = setInterval(() => {
          setRecordingTime((t) => t ? t+1 : 1);
        }, 1000);
        setIntervalT(inter);
    } else {
        recorder?.state !== 'inactive' && recorder.stop();
        clearInterval(interval);
        setRecordingTime(null);
    }


    const handleData = e => {
      const note = URL.createObjectURL(e.data);
      setAudioURL(note);
      onNoteDone && onNoteDone(note);
      getBlob && getBlob(new Blob([e.data], {type: 'audio/webm'}));
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const clearRecordingTime = () => {
    setRecordingTime(null);
  };


  return {audioURL, isRecording, recordingTime, startRecording, stopRecording, clearRecordingTime};
};



async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}

export default useAudioRecorder;
