import React from 'react';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
  useTheme
} from '@mui/material/styles';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import joyThemeOption from './joyOptions';

const CustomJoyTheme = ({ children }) => {
  const defaultTheme = useTheme();

  const joyTheme = extendTheme(joyThemeOption);
  const materialTheme = materialExtendTheme(defaultTheme);

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider defaultMode="light" modeStorageKey="_joy_mode_custom" theme={joyTheme}>
        <CssBaseline />
        {children}
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default CustomJoyTheme;
