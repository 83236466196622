import React from 'react';
import { CircularProgress, Dialog, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MinimizeIcon from '@mui/icons-material/Minimize';

export default function FilesReader({ open, onClose, docUrl, fileName, docType }) {
  const [loading, setLoading] = React.useState(true);
  const [fullscreen, setFullScreen] = React.useState(false);

  const handleLoad = () => {
    setLoading(false);
  };

  const toogleScreen = () => {
    setFullScreen(!fullscreen);
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullscreen}
      {...(!fullscreen && { maxWidth: 'md' })}
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 9999
      }}
      PaperProps={{
        sx: {
          height: fullscreen ? '98vh' : '90vh',
          border: 'none',
          boxShadow: 'none',
          bgcolor: 'transparent'
        }
      }}
    >
      <Stack p={3}>
        <Stack
          sx={{
            position: 'relative',
            height: fullscreen ? '98vh' : '80vh',
            bgcolor: (t) => t.palette.background.paper
          }}
        >
          <embed src={docUrl} onLoad={handleLoad} width="100%" height="100%" type="application/pdf" title={fileName} />
          {loading && (
            <Stack
              width={1}
              justifyContent="center"
              alignItems="center"
              bgcolor="#FFF"
              sx={{ position: 'absolute', top: '0', height: '80vh' }}
            >
              <CircularProgress />
            </Stack>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          p={0.3}
          alignItems="center"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '5px',
            borderRadius: 1,
            backgroundColor: 'background.paper'
          }}
        >
          <IconButton size="small" onClick={toogleScreen} sx={{ p: 0.2 }}>
            {!fullscreen ? <FullscreenIcon fontSize="small" /> : <MinimizeIcon fontSize="small" />}
          </IconButton>
          <IconButton size="small" onClick={onClose} sx={{ p: 0.2 }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
