import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, uniq } from 'lodash';
import { AFFECT_STATE_VALIDATION, NOTIFICATION_TYPES, TASK_STATE_VALIDATION } from 'src/constants';
import { auth, firestore } from 'src/contexts/FirebaseContext';
import { AffectationType } from 'src/models/Affectation_m';
import { changeOnObject } from 'src/utils/changeOnObject';
import { serverTime } from 'src/utils/serverTime';
import { createNotification } from 'src/redux/slices/notifications';
import { getCurrentUserAccess, getDocChanges } from 'src/helpers/user';
import { commentNotif, creationNotif, updateNotif } from './notifications/notif_affectation';

const initialState = {
  error: false,
  isLoading: false,
  affectation: []
};

const slice = createSlice({
  name: 'affectation',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createdSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function generateRef(setval) {
  return async (dispatch) => {
    try {
      const numberDoc = await firestore.collection('references').doc('affectation').get();
      if (numberDoc.exists) {
        const val = numberDoc.data()?.number;
        setval(val);
        numberDoc.ref.update({ number: val + 1 });
        return;
      }
      setval(1);
      numberDoc.ref.set({ number: 2 });
    } catch (error) {
      console.error(error);
    }
  };
}

export const getAffectationById = (id, resolve, reject) => {
  return async (dispatch, getStore) => {
    try {
      //console.log('id', id);
      const user = getCurrentUserAccess();
      
      const reviews = getStore().firestore.ordered[`affectation-toReview-${user?.id}`];
      const todos = getStore().firestore.ordered[`affectation-Todo-${user?.id}`];
      const list = [...todos, ...reviews];
      const find = list?.find((el) => el?.id === id);

      if (find) {
        resolve(find);
        return;
      }

      const snap = await firestore.collection('affectation').doc(id).get();

      if (snap.exists) {
        resolve({ ...snap.data(), id: snap.id });
      } else {
        reject();
      }
    } catch (error) {
      console.error(error);
            reject();
    }
  };
};

/**
 * @param {{
 *  affectation: AffectationType,
 * callback: Function?
 * }}
 */
export function createAffectation({ affectation, callback = null }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const currentUser = auth.currentUser;
      const { responsables, ...rest } = affectation.responsable;
      const { comment } = affectation.correspondance;

      const toSave = {
        ...affectation,
        isDelete: false,
        responsable: rest,
        toDoIds: uniq([rest.id, currentUser.uid, ...responsables]),
        state: AFFECT_STATE_VALIDATION.NOT_ASSIGN,
        createdAt: serverTime(),
        updatedAt: serverTime(),
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          avatar: currentUser.photoURL,
          name: currentUser.displayName
        },
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          avatar: currentUser.photoURL,
          name: currentUser.displayName
        }
      };

      const addRef = await firestore.collection('affectation').add(toSave);
      dispatch(slice.actions.createdSuccess());
      callback && callback();

      if (!isEmpty(comment)) {
        await addRef.collection('comments').add({
          userId: currentUser.uid,
          avatar: currentUser.photoURL,
          name: currentUser.displayName,
          email: currentUser.email,
          messageType: 'text',
          createdAt: serverTime(),
          message: comment || '',
          read: 0,
          readState: [],
          mentions: []
        });
      }

      dispatch(creationNotif({ affect: { ...toSave, id: addRef.id } }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/** @param {{ affect: AffectationType, oldAffect: AffectationType, isPrint: Boolean, callback: Function }} */
export function updateAffectation({ affect, oldAffect, isPrint = false, callback }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { id, ...rest } = affect;
      const currentUser = auth.currentUser;
      const docRef = firestore.collection('affectation').doc(affect.id);

      if (!isPrint) {
        docRef.set(
          {
            ...rest,
            toDoIds: uniq([...rest.toDoIds]),
            toReviewIds: uniq([...rest.toReviewIds]),
            historyIds: uniq([...rest.historyIds]),
            updatedAt: serverTime(),
            updatedBy: {
              id: currentUser.uid,
              email: currentUser.email,
              avatar: currentUser.photoURL,
              name: currentUser.displayName
            }
          },
          { merge: true }
        );
      } else {
        docRef.set({ canPrint: affect.canPrint }, { merge: true });
      }

      const change = changeOnObject({ id: null, ...oldAffect }, { id: null, ...affect });
      dispatch(updateNotif({ affect: affect, change: change, oldAffect: oldAffect }));

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function addAffectComment({ commentObject, generateId, affect, callback }) {
  return async (dispatch) => {
    try {
      await firestore
        .collection('affectation')
        .doc(affect.id)
        .collection('comments')
        .doc(generateId)
        .set(commentObject);

      callback && callback();

      dispatch(commentNotif({ affect: affect }));
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteAffect({ affect, callback }) {
  return async (dispatch) => {
    try {
      await firestore.collection('affectation').doc(affect.id).set({ isDelete: true }, { merge: true });
      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function archiveAffect({ affect, callback }) {
  return async (dispatch) => {
    try {
      const currentUser = auth.currentUser;

      await firestore
        .collection('affectation')
        .doc(affect.id)
        .set(
          {
            ...affect,
            toDoIds: [],
            toReviewIds: [],
            archivedIds: affect.historyIds,
            isAchiver: true,
            historyIds: [],
            updatedAt: serverTime(),
            updatedBy: {
              id: currentUser.uid,
              email: currentUser.email,
              avatar: currentUser.photoURL,
              name: currentUser.displayName
            }
          },
          { merge: true }
        );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function archiveAffectMultiple({ selected = [], callback }) {
  return async (dispatch) => {
    try {
      const batchSize = 400;

      for (let i = 0; i < selected.length; i += batchSize) {
        const batch = firestore.batch();
        const batchIds = selected.slice(i, i + batchSize);

        batchIds.forEach((id) => {
          batch.update(firestore.collection('affectation').doc(id), { isDelete: true, isAchiver: true });
        });

        await batch.commit();
      }

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteAffectMultiple({ selected = [], callback }) {
  return async (dispatch) => {
    try {
      const batchSize = 400;

      for (let i = 0; i < selected.length; i += batchSize) {
        const batch = firestore.batch();
        const batchIds = selected.slice(i, i + batchSize);

        batchIds.forEach((id) => {
          batch.update(firestore.collection('affectation').doc(id), { isDelete: true, isAchiver: false });
        });

        await batch.commit();
      }

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function removeAffectComment({ id, affect, callback }) {
  return async (dispatch) => {
    try {
      await firestore.collection('affectation').doc(affect.id).collection('comments').doc(id).delete();
      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

//#region notification
const emitNotification = (old, current) => {
  return (dispatch) => {
    dispatch(accessNot(old, current));
    dispatch(stateNot(old, current));
    dispatch(otherNot(old, current));
  };
};

//#region access
const accessNot = (old, current) => {
  return (dispatch) => {
    if (old?.responsable?.id !== current?.responsable?.id) {
      if (old?.responsable != null) {
        dispatch(revokeNot(current, [old?.responsable], 'Responsable'));
      }
      if (current?.responsable != null) {
        dispatch(assignedNot(current, [current?.responsable], 'Responsable'));
      }
    }

    const destSnap = getDocChanges(old?.assigne?.responsable, current?.assigne?.responsable);
    dispatch(assignedNot(current, destSnap?.added, 'Destinataire'));
    dispatch(revokeNot(current, destSnap?.removed, 'Destinataire'));

    const collabSnap = getDocChanges(old?.assigne?.collaborateur, current?.assigne?.collaborateur);
    dispatch(assignedNot(current, collabSnap?.added, 'Collaborateur'));
    dispatch(revokeNot(current, collabSnap?.removed, 'Collaborateur'));
  };
};

const assignedNot = (affectation, targets = [], userAs = 'Collaborateur', revoke = false) => {
  return (dispatch) => {
    if (targets?.length === 0) return;

    let user = getCurrentUserAccess();

    let type = NOTIFICATION_TYPES.AFFECTATION_REVOKE_USER;
    let title = 'Une affectation vous a été assignée';
    let description = `Vous avez été assigné à l'affectation ${affectation?.correspondance?.reference} en tant que ${userAs}  par ${user?.name}`;

    if (revoke) {
      type = NOTIFICATION_TYPES.AFFECTATION_ASSIGN_USER;
      title = 'Une affectation vous a été retirée';
      description = `Vous avez été retiré de l'affectation ${affectation?.correspondance?.reference} par ${user?.name}`;
    }

    dispatch(
      notifyForAffectation({
        affectation: affectation,
        receivers: targets,
        title,
        description,
        type
      })
    );
  };
};
const revokeNot = (affectation, targets = [], userAs = 'Collaborateur') =>
  assignedNot(affectation, targets, userAs, true);

//#endregion

const stateNot = (old, current) => {
  return (dispatch) => {
    if (old?.state === current?.state) return;

    const user = getCurrentUserAccess();

    let title = '';
    let description = '';
    let targets = [];

    if (current?.state === AFFECT_STATE_VALIDATION.DONE) {
      title = 'Affectation terminé';
      description = `L'affectation '${current?.correspondance?.reference}' a été achevée par ${user?.name}`;
      targets = current?.assigne?.responsable;
    }

    if (current?.state === AFFECT_STATE_VALIDATION.ONVALIDATE && current?.responsable != null) {
      title = 'Demande de validation';
      description = `${user.name} demande la validation sur l'affectation '${current.correspondance.reference}'`;
      targets = [current?.responsable];
    }

    if (current?.state === AFFECT_STATE_VALIDATION.ACCEPTED) {
      title = 'Affectation validée';
      description = `${user?.name} a validé l'affectation '${current?.correspondance?.reference}'`;
      targets = [...current?.assigne?.responsable, ...current?.assigne?.collaborateur];
    }

    if (current?.state === AFFECT_STATE_VALIDATION.REJECTED) {
      title = 'Affectation rejetée';
      description = `${user?.name} a rejetée l'affectation '${current?.correspondance?.reference}'`;
      targets = [...current?.assigne?.responsable, ...current?.assigne?.collaborateur];
    }

    dispatch(
      notifyForAffectation({
        affectation: current,
        receivers: targets,
        title,
        description,
        type: NOTIFICATION_TYPES.AFFECTATION_STATE_CHANGE
      })
    );
  };
};

const otherNot = (old, current) => {
  return (dispatch) => {
    const parseRest = (val) => {
      const { state, responsable, assigne, updatedBy, updatedAt, ...rest } = val;
      return JSON.stringify(rest);
    };

    if (parseRest(old) !== parseRest(current)) {
      const user = getCurrentUserAccess();

      const title = 'Voici ce que vous avez manqué';
      const description = `${user?.name} a apporté des modifications à l'affectation ${current?.correspondance?.reference}`;

      const receivers = [...current?.assigne?.collaborateur, ...current?.assigne?.responsable];

      dispatch(
        notifyForAffectation({
          affectation: current,
          receivers,
          title,
          description,
          type: NOTIFICATION_TYPES.AFFECTATION_OTHER_CHANGE
        })
      );
    }
  };
};

const commentNot = (current, comment) => {};

//#endregion

const notifyForAffectation = ({
  affectation,
  title = '',
  description = '',
  receivers = [],
  type = 'affectation',
  userAs = null
}) => {
  return (dispatch) => {
    const canAccess = uniq(receivers?.map((el) => el?.id));
    let isUnRead = {};

    canAccess.forEach((id) => (isUnRead[id] = true));

    dispatch(
      createNotification({
        data: {
          type,
          title,
          description,
          canAccess,
          isUnRead,
          createdAt: serverTime(),
          returnId: affectation?.id,
          action: {
            id: affectation?.id,
            ref: affectation?.correspondance?.reference,
            userAs
          }
        }
      })
    );
  };
};
