import { auth } from 'src/contexts/FirebaseContext';

export const getCurrentUserAccess = () => {
  const currentUser = auth.currentUser;

  return {
    id: currentUser.uid,
    email: currentUser.email,
    avatar: currentUser.photoURL,
    name: currentUser.displayName
  };
};

export const transformUserToAccess = (user) => {
  //
  return {
    id: user?.id,
    name: user?.name || user?.displayName || `${user?.lastName || ''} ${user?.firstName || ''} ` || '',
    avatar: user?.avatar || user?.photoUrl || user?.photoURL || null,
    email: user?.email || user?.userEmail || null,
    last_changed: user?.last_changed || null,
    token: user?.token || null,
    tel: user?.tel || user?.phoneNumber || null
  };
};

export const toSimpleAccessUser = (user) => {
  //
  return {
    id: user?.id,
    name: user?.name || user?.displayName || `${user?.lastName || ''} ${user?.firstName || ''} ` || '',
    avatar: user?.avatar || user?.photoUrl || user?.photoURL || null,
    email: user?.email || user?.userEmail || null
  };
};

export const getDocChanges = (old = [], current = []) => {
  const added = current.filter((el) => old.filter((e) => e.id === el.id).length === 0);

  const removed = old.filter((el) => current.filter((e) => e.id === el.id).length === 0);

  return { added, removed };
};
