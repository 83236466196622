import { Paper, Stack, alpha } from '@mui/material';
import React from 'react';

/**
 * @param {import('@mui/material').SxProps} param0
 */
export const GridStack = ({ children, sx, ...other }) => (
  <Stack fontSize={14} height={1} width={1} justifyContent="center" alignItems="center" sx={sx} {...other}>
    {children}
  </Stack>
);
/**
 *
 * @param {{
 *  variant: "elevation" | "outlined",
 *  height: number,
 *  cols: Array<{
 *           element: React.ReactNode,
 *           width: number | string,
 *           minWidth: number | string,
 *           customWidth: number | string,
 *           display: string,
 *           borderLeft: string,
 *           borderBottom: string
 *           maxWidth: number | string
 *        }>
 * }} props
 * @returns
 */
export const TableBaseLine = ({ variant = 'elevation', height = 40, cols = [], position, ...other }) => {
  return (
    <Stack
      direction="row"
      spacing={0}
      width={1}
      height={height}
      sx={{
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
      }}
      {...other}
    >
      {cols.map((_col, idx) => (
        <Stack key={idx} width={_col?.width || 1} maxWidth={1} minWidth={_col?.minWidth || 100}>
          <Stack
            width={_col?.customWidth || 1}
            minHeight={height}
            variant={variant}
            {...(variant === 'elevation' && { elevation: 5 })}
            {...(_col?.maxWidth && { maxWidth: _col?.maxWidth })}
            {...(_col?.display && { display: _col?.display })}
            {...(_col?.borderLeft && { borderLeft: _col?.borderLeft })}
            {...(_col?.borderBottom && { borderBottom: _col?.borderBottom })}
          >
            {_col?.element}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
