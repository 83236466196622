import { useState } from 'react';

import { COLOR_OPTIONS } from 'src/section/project/ProjectCardSimple';

import { Stack, Paper, TextField, Button, Typography, IconButton, Dialog } from '@mui/material';
import ColorSinglePicker from 'src/components/ColorSinglePicker';
import Center from 'src/components/Center';

import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';

export default function MailAccountColor({ account, onChoose, open, onClose }) {
  const [color, setColor] = useState();

  const handleChangeColor = (event) => setColor(event.target.value);

  const handleChoice = () => {
    onChoose && onChoose(account, color);
    onClose && onClose();
  };

  const iconSize = { height: 20, width: 20 };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      fullScreen
      PaperProps={{ sx: { bgcolor: 'transparent' } }}
    >
      <Center>
        <Stack component={Paper} width={400} spacing={2} borderRadius={1} my={4} variant="outlined">
          <Stack width={1} py={1.5} pl={3} pr={1} direction={'row'} justifyContent={'space-between'}>
            <Stack justifyContent={'center'} height={1}>
              <Typography variant={'h6'}>Choix de la couleur du compte</Typography>
            </Stack>

            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>

          <Stack px={2} spacing={3}>
            <Stack px={2}>
              <TextField
                variant={'standard'}
                value={account?.user?.email}
                InputProps={{
                  endAdornment: <Icon icon={'material-symbols:mail-outline'} {...iconSize} />
                }}
              />
            </Stack>

            <ColorSinglePicker selected={color} onChange={handleChangeColor} colors={COLOR_OPTIONS} />
          </Stack>

          <Stack direction={'row'} justifyContent={'end'} width={1} spacing={3} bgcolor={'#F3F2F7'} py={1} px={2}>
            <Button variant={'contained'} size={'small'} color={'inherit'} onClick={onClose}>
              Sauter
            </Button>
            <Button variant={'contained'} size={'small'} onClick={handleChoice}>
              Choisir
            </Button>
          </Stack>
        </Stack>
      </Center>
    </Dialog>
  );
}
