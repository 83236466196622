import { Button, Stack, Typography, colors } from '@mui/material';
import React, { useMemo } from 'react';
import ActionModal from 'src/components/ActionModal';

export default function AddUserToProjectDialog({ open, onClose, users = [], onValidate }) {
 
  const many = useMemo(() => Boolean(users.length > 1), [users]);
  const userList = users.map((user) => user.name).join(', ');
    
  //console.log({ users, many, userList });
  return (
    <ActionModal
      open={open}
      onClose={onClose}
      title="Confirmation"
      desc={
        <Stack spacing={2}>
          <Typography variant="body">
            {many ? manyUsers(userList) : oneUser(userList)}
          </Typography>
        </Stack>
      }
      closeText="Ne pas ajouter"
      action={
        <Button variant="contained" onClick={onValidate}>
          Ajouter
        </Button>
      }
    />
  );
}

const oneUser = (user) => <Typography>L'utilisateur <span style={{color: '#ffc107'}}>{user}</span> ne fait pas partie des intervenants de ce projet. Voulez-vous l'ajouter ?</Typography>;
const manyUsers = (users) => <Typography>Les utilisateurs <span style={{color: '#ffc107'}}>{users}</span> ne font pas partie des intervenants de ce projet. Voulez-vous les ajouter ?</Typography>;