import { last } from 'lodash';
import PropTypes from 'prop-types';
// material
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box, Link, Breadcrumbs, Tooltip } from '@mui/material';
// -------------
//---------------------------------------------------------

const Separator = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.disabled'
    }}
  />
);

LinkItem.propTypes = {
  link: PropTypes.object
};

function LinkItem({ link, maxWidth, typographyStyle }) {
  const { href, name, icon, folder, path } = link;

  return (
    <Tooltip title={name || ''} arrow placement="bottom">
      <Link
        to={{
          pathname: href,
          state: { folder: { ...folder, path: path } }
        }}
        key={name}
        variant="body2"
        component={RouterLink}
        sx={{
          lineHeight: 2,
          display: 'flex',
          alignItems: 'center',
          color: 'text.primary',
          maxWidth: maxWidth || 150,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          '& > div': { display: 'inherit' },
          ...typographyStyle
        }}
      >
        {icon && (
          <Box
            sx={{
              mr: 1,
              '& svg': { width: 20, height: 20 }
            }}
          >
            {icon}
          </Box>
        )}
        {name}
      </Link>
    </Tooltip>
  );
}

DocMBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool
};

export default function DocMBreadcrumbs({
  links,
  activeLast = false,
  maxWidth,
  typographyStyle,
  color,
  separator,
  ...other
}) {
  const currentLink = last(links)?.name;

  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />);

  const listActiveLast = links.map((link, idx) => (
    <div key={idx}>
      {link.name !== currentLink ? (
        <LinkItem link={link} typographyStyle={typographyStyle} />
      ) : (
        <Tooltip title={link.name || ''} arrow placement="bottom">
          <Typography
            variant="body2"
            sx={{
              maxWidth: maxWidth || 150,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: color || 'text.disabled',
              textOverflow: 'ellipsis'
            }}
            {...typographyStyle}
          >
            {currentLink}
          </Typography>
        </Tooltip>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={separator || Separator} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
}
