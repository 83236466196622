import { Button, ClickAwayListener, Paper, Stack, Typography, IconButton, Tooltip } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import React, { useState } from 'react';
import useTaskByWeek from 'src/hooks/useTaskByWeek';
import useWeekDays from 'src/hooks/useWeekDays';
import { gDate } from 'src/utils/formatTime';
import V4ProjectWeekCalendarBody from './V4ProjectWeekCalendarBody';
import { useToggleV2 } from 'src/hooks/useToggle';
import { Icon } from '@iconify/react';
import { TaskType } from 'src/models/task_m';
import { isEmpty } from 'lodash';
import { dispatch } from 'src/redux/store';
import { useV4ProjectContext } from '..';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { MuiCustomInput } from 'src/components/CustomInput';
import { addTask } from 'src/redux/slices/kanban';
import { nanoid } from '@reduxjs/toolkit';
import { Droppable } from 'react-beautiful-dnd';

export default function V4ProjectWeekCalendar({ user, projectId }) {

  const { weeks, nextWeek, previousWeek } = useWeekDays();
  const { tasks } = useTaskByWeek({
    first: weeks[1].date,
    end: weeks[7].date,
    userId: user?.uid,
    projectId: projectId
  });

  // Obtenir le mois courant
  const currentMonth = weeks[1].date?.toLocaleString('default', { month: 'long' });
  const currentYear = weeks[1].date?.getFullYear();

  return (
    <Stack
      width={1}
      direction="column"
      alignItems="flex-start"
      pb={2}
      mb={1}
      component={Paper}
      variant="outlined"
    >

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} width={1} px={2} py={1}>

        <Tooltip title="Semaine précédente">
          <IconButton onClick={previousWeek} sx={{ ml: 1 }}>
            <NavigateBefore />
          </IconButton>
        </Tooltip>

        <Typography fontWeight="bold" >
          {weeks[1].day} au {weeks[7].day}
        </Typography>
        <Typography fontWeight="bold" sx={{ ml: 1 }}>
          {currentMonth} {currentYear}
        </Typography>
        <Tooltip title="Semaine suivante">
          <IconButton onClick={nextWeek} >
            <NavigateNext />
          </IconButton>
        </Tooltip>
      </Stack>



      <Stack
        width={1}
        direction="row"
        alignItems="flex-start"
        pb={2}
        sx={{
          minHeight: '80vh',
          height: 'calc(100% - 32px)',
          overflowY: 'hidden',
          borderTop: (t) => `1px solid ${t.palette.divider}`
        }}
      >

        {Object.entries(weeks).map(([_, item], idx) => {
          const sectionTasks = tasks.filter((task) => {
            const deadLine = gDate(task?.deadLine);
            return deadLine?.getDate() === item.day;
          });

          return <DaySection key={item.day + '_' + idx} {...item} tasks={sectionTasks} />;
        })}

      </Stack>
    </Stack>
  );
}

/**
 *
 * @param {{day: number, date: Date, dayShort :string, currentDay: boolean, tasks: Array}} props
 * @returns
 */
const DaySection = ({ day, date, dayShort, currentDay, tasks = [] }) => {
  const [open, onOpen, onClose] = useToggleV2();
  const [hover, onHover, endHover] = useToggleV2();

  return (
    <Droppable droppableId={date?.toLocaleDateString()} type="task-calendar">
      {(provided, snapshot) => (
        <Stack
          {...provided.droppableProps}
          ref={provided.innerRef}
          minWidth={250}
          maxWidth={250}
          borderRight={(t) => `1px solid ${t.palette.divider}`}
        >
          <Stack p={1} bgcolor={(t) => t.palette.background.paper} onMouseEnter={onHover} onMouseLeave={endHover}>
            <Stack direction="row" justifyContent="space-between">
              <Typography fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                {dayShort}
              </Typography>
              {hover && (
                <Button
                  size="small"
                  color="inherit"
                  startIcon={<Icon icon="eva:plus-fill" />}
                  sx={{ p: 0 }}
                  onClick={onOpen}
                >
                  Ajouter une tâche
                </Button>
              )}
            </Stack>
            <Stack
              width={32}
              height={30}
              textAlign="center"
              justifyContent="center"
              {...(currentDay && { bgcolor: 'info.main', borderRadius: 1, color: 'white' })}
            >
              <Typography fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                {day}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            p={1}
            spacing={1}
            sx={{
              p: 0.5,
              transition: 'background-color 0.3s ease',
              ...(snapshot.isDraggingOver && { bgcolor: 'rgba(233, 255, 90, 0.2)' })
            }}
          >
            <V4ProjectWeekCalendarBody tasks={tasks} />
            {provided.placeholder}
            {open && <V4ProjectWeekcalendarNew onClose={onClose} date={date} />}
            <Button size="small" color="inherit" startIcon={<Icon icon="eva:plus-fill" />} onClick={onOpen}>
              Ajouter une tâche
            </Button>
          </Stack>
        </Stack>
      )}
    </Droppable>
  );
};

const V4ProjectWeekcalendarNew = ({ onClose, date }) => {
  const [task, setTask] = useState(TaskType);
  const { projectId, board } = useV4ProjectContext();

  const handleChangeName = (e) => {
    const val = e.currentTarget.value;
    setTask((prev) => ({ ...prev, name: val }));
  };

  const handleCreateNewTask = () => {
    const onReset = () => {
      onClose();
      //console.log({ task });
    };
    if (!isEmpty(task?.name)) {
      dispatch(
        addTask(
          {
            card: {
              ...task,
              due: [date, date],
              deadLine: date,
              projectKey: projectId,
              id: nanoid()
            },
            ...(board?.columnOrder?.length &&
              board?.columnsOnArray?.length && {
              columnId: board?.columnOrder?.at(0)
            })
          },
          onReset,
          onReset
        )
      );
    }

    onClose();
  };

  let counter = 0;
  const handleKeyUp = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      if (counter < 1) {
        handleCreateNewTask();
        counter += 1;
        return;
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Stack width={1} direction="row" component={Paper} variant="outlined" alignItems="center">
        <Stack width={1}>
          <MuiCustomInput
            multiline
            maxRows={3}
            minRows={1}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            value={task.name}
            onChange={handleChangeName}
            autoFocus
            fullWidth
            size="small"
            sx={{
              py: 0.3,
              fontSize: 13,
              border: 'none'
            }}
          />
        </Stack>
      </Stack>
    </ClickAwayListener>
  );
};
