import {createSlice, nanoid} from '@reduxjs/toolkit';
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import axios from '../../utils/axios';
import objFromArray from '../../utils/objFromArray';
import firestore from 'src/utils/firestore';
import {multipleFilesSave, SaveChatDocs} from './document';
import {chatCreationNotification, chatMentionNotification} from './notifications';
import {isFunction,capitalize} from 'lodash';
import {serverTime} from 'src/utils/serverTime';
import {gDate} from 'src/utils/formatTime';
import {registerMessage, persitMessage} from "./conversation";
import {getFileFormat} from 'src/utils/getFileFormat'


// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    contacts: {byId: {}, allIds: []},
    conversations: {byId: {}, allIds: []},
    arrayConversations: [],
    activeConversationId: null,
    participants: [],
    recipients: [],
    pendingConversation: [],
    pendingMessage: {},
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {


        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CONTACT SSUCCESS
        getContactsSuccess(state, action) {
            const contacts = action.payload;
            state.contacts.byId = objFromArray(contacts);
            state.contacts.allIds = Object.keys(state.contacts.byId);
            state.isLoading = false;
        },

        // GET CONVERSATIONS
        getConversationsSuccess(state, action) {
            const data = action.payload;

            data.forEach((conv) => {
                const {changetype, ...rest} = conv;
                switch (changetype) {
                    case 'added':
                        state.conversations.byId[rest.id] = rest;
                        if (!state.arrayConversations.find(conv => conv.id === rest.id)) {
                            state.arrayConversations.push(rest);
                        }
                        break;
                    case 'modified':
                        state.conversations.byId[rest.id].lastSend = rest.lastSend;
                        state.conversations.byId[rest.id].lastMessage = rest.lastMessage;
                        state.conversations.byId[rest.id].unreadCount = rest.unreadCount;
                        let exist = state.arrayConversations.find(con => con.id === rest.id);
                        if (exist) {
                            exist.lastMessage = rest.lastMessage;
                            exist.lastSend = rest.lastSend;
                        }
                        break;
                    case 'removed':

                        break;
                    default:
                        break;
                }
            });

            state.arrayConversations.length > 0 && state.arrayConversations.sort((a, b) => b.lastSend.seconds - a.lastSend.seconds);
            const orderdConv = [];
            state.arrayConversations.map((conv) => {
                orderdConv.push(conv.id);
                return conv;
            });
            state.conversations.allIds = orderdConv;
            state.isLoading = false;
        },

        // GET CONVERSATION
        getConversationSuccess(state, action) {
            const {conversation, messages: {add}} = action.payload;


            if (state.conversations.byId[conversation.id]) {
                const msgs = state.conversations.byId[conversation.id].messages;
                add.forEach(msg => {
                    const exist = msgs.find(m => m.id === msg.id);
                    if (!exist) {
                        state.conversations.byId[conversation.id].messages.push(msg);
                    }
                });
                state.activeConversationId = conversation.id;
                if (!state.conversations.allIds.includes(conversation.id)) {
                    state.conversations.allIds.push(conversation.id);
                }
            } else {
                state.activeConversationId = null;
            }
            state.isLoading = false;
        },

        // ON SEND MESSAGE
        onSendMessage(state, action) {
            const conversation = action.payload;
            const {
                conversationId,
                messageId,
                message,
                contentType,
                attachments,
                createdAt,
                senderId
            } = conversation;

            const newMessage = {
                id: messageId,
                body: message,
                contentType,
                attachments,
                createdAt,
                senderId
            };
            state.conversations.byId[conversationId].messages.push(newMessage);
            state.isLoading = false;
        },

        markConversationAsReadSuccess(state, action) {
            const {conversationId} = action.payload;
            const conversation = state.conversations.byId[conversationId];
            if (conversation) {
                conversation.unreadCount = 0;
            }
            state.isLoading = false;
        },

        // GET PARTICIPANTS
        getParticipantsSuccess(state, action) {
            const participants = action.payload;
            state.participants = participants;
            state.isLoading = false;
        },

        // RESET ACTIVE CONVERSATION
        resetActiveConversation(state) {
            state.activeConversationId = null;
            state.isLoading = false;
        },

        addRecipients(state, action) {
            const recipients = action.payload;
            state.recipients = recipients;
            state.isLoading = false;
        },


        newConversationCreate(state, action) {
            state.isLoading = false;
        }

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    addRecipients,
    onSendMessage,
    resetActiveConversation,
    getContactsSuccess
} = slice.actions;

// ----------------------------------------------------------------------
export function onNewMessage(messageObject, unreadCount, senderId, callback = null, onUploadProgress) {
    return async (dispatch) => {
        try {
            const id = messageObject?.id || nanoid();


            dispatch(slice.actions.startLoading());
            const {conversationId, message, attachments, ...messageProperties} = messageObject;

            dispatch(persitMessage({
                message: {
                    ...messageObject,
                    id
                },
                conversationId
            }))



            let   lastMessage= ''

            const action = async (files = []) => {
                const docRef = firestore.collection('conversations').doc(conversationId);


                const getLastMessage = () => {
                    if (messageProperties?.audio) {
                        return "Note vocale";
                    }
                    if (files?.length > 1) {
                        return ` (${files?.length}) x Fichiers attachés`;
                    }

                    if (files?.length === 1) {
                        const type = files[0]?.type?.replace('/', '.');
                        const format = getFileFormat(type);

                        if (format === 'audio') {
                            return "Audio"
                        }
                        if (format === 'image') {
                            return "Image"
                        }
                        if (["photoshop", "illustrator",].includes(format)) {
                            return "Illustration"
                        }
                        if (format === 'video') {
                            return "Video"
                        }

                        if (["word", "excel", "powerpoint", "pdf"].includes(format)) {
                            return  capitalize(format)
                        }

                        return "Fichier attaché"
                    }

                    return message;
                }

                lastMessage=getLastMessage()

                await docRef.set({
                    lastSend: serverTime(),
                    lastMessage,
                    unreadCount: unreadCount + 1,
                    lastSenderId: senderId
                }, {merge: true});

                await docRef.collection('messages').doc(id)
                    .set({
                        ...messageProperties,
                        attachments: files,
                        body: message,
                        createdAt: serverTime()
                    }, {merge: true});

                dispatch(registerMessage(conversationId, id))

                const conv = {
                    messageId: id,
                    conversationId, ...messageProperties, attachments: files,
                    body: message
                };
                dispatch(slice.actions.newConversationCreate(conv));
            };

            if (attachments.length > 0) {
                await multipleFilesSave(
                    attachments,
                    async (files) => {
                        await action(files);
                        dispatch(SaveChatDocs(files));
                    }, null, onUploadProgress
                );
            } else {
                await action();
            }


            if (callback) {
                callback()
            }
            dispatch(chatCreationNotification({data: {...messageObject,lastMessage}}));

            const mentions = messageObject?.mentions || []

            if (mentions.length !== 0) {
                dispatch(chatMentionNotification({
                    conversationId,
                    messageId:id,
                    canReceived: mentions
                }))
            }
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const markMessageAsRead = ({conversationId, messageIds = [], userId}, callback = null) => {
    return async () => {
        try {


            await Promise.all(
                messageIds?.map(async id => await firestore.runTransaction(transaction => {
                    const ref = firestore.collection('conversations').doc(conversationId)
                        .collection('messages').doc(id)

                    return transaction.get(ref).then(doc => {

                        if (doc.exists) {

                            const hasReadIds = [
                                ...(doc.data()?.hasReadIds || []),
                                userId
                            ];

                            const readingTimeLine = [
                                ...(doc.data()?.readingTimeLine || []),
                                {
                                    userId,
                                    date: new Date()
                                }
                            ]

                            transaction.update(ref, {
                                hasReadIds,
                                readingTimeLine
                            })

                        }
                    })
                }))
            )


        } catch (e) {
            console.error(e)
        }
    }
}

// ----------------------------------------------------------------------

export function getContacts() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/chat/contacts');
            dispatch(slice.actions.getContactsSuccess(response.data.contacts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConversationById({conversationId, onResolve, onReject}) {
    return async (dispatch, getStore) => {
        try {
            const list = getStore()?.firestore.ordered?.conversations || []
            const exist = list?.find(el => el?.id === conversationId)


            if (exist && onResolve) onResolve(exist)

            const snap = await firestore.collection('conversations').doc(conversationId).get();


            if (snap.exists && onResolve) onResolve({...snap.data(), id: snap.id})
            else if (onReject) onReject('not found')


        } catch (e) {
            //console.log(e)
            if (onReject) onReject(1)
        }
    }
}

// ----------------------------------------------------------------------

export function getConversations(userId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            firestore.collection('conversations')
                .where('participantsId', 'array-contains', userId)
                .limit(50)
                .onSnapshot((snapshot) => {
                    const data = [];
                    snapshot.docChanges().forEach(docChang => {
                        const {type, doc} = docChang;
                        data.push({
                            changetype: type,
                            id: doc.id,
                            ...doc.data(),
                            createdAt: gDate(doc.data().createdAt) || new Date(),
                            messages: []
                        });
                    });

                    dispatch(slice.actions.getConversationsSuccess(data));
                });

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            console.error(error);
        }
    };
}

// ----------------------------------------------------------------------

export function getConversation(conversation) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            firestore.collection('conversations')
                .doc(conversation.id)
                .collection('messages')
                .orderBy('createdAt', 'asc')
                .limitToLast(50)
                .onSnapshot(snapshot => {
                    const add = [], remove = [], update = [];
                    snapshot.docChanges().forEach(docChang => {
                        const {type, doc} = docChang;

                        switch (type) {
                            case 'added':
                                add.push({
                                    id: doc.id,
                                    ...doc.data(),
                                    createdAt: gDate(doc.data().createdAt) || new Date()
                                });
                                break;
                            case 'modified':
                                update.push({
                                    id: doc.id,
                                    ...doc.data(),
                                    createdAt: gDate(doc.data().createdAt) || new Date()
                                });
                                break;
                            case 'removed':
                                remove.push({
                                    id: doc.id,
                                    ...doc.data(),
                                    createdAt: gDate(doc.data().createdAt) || new Date()
                                });
                                break;

                            default:
                                break;
                        }

                    });
                    let conv = {conversation, messages: {add, remove, update}};
                    dispatch(slice.actions.getConversationSuccess(conv));
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await firestore.collection('conversations').doc(conversationId).set({unreadCount: 0}, {merge: true});
            dispatch(slice.actions.markConversationAsReadSuccess({conversationId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/chat/participants', {
                params: {conversationKey}
            });
            dispatch(
                slice.actions.getParticipantsSuccess(response.data.participants)
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function onCreateNewRoot(newChatRoom, navigate) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let doc = await firestore.collection('conversations').add(newChatRoom);
            navigate(`${PATH_DASHBOARD.chat.root}/${doc.id}`);
            dispatch(
                slice.actions.newConversationCreate(doc.id)
            );
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createConversation(conversation, callback = null, onError = null) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const snap = await firestore.collection('conversations').add(conversation);

            if (callback) {
                callback({...conversation, id: snap.id})
            }

        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
            if (onError) {
                onError()
            }
        }
    };
}

// ----------------------------------------------------------------------

export function ActiveConversation(newChatRoom, navigate) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let doc = await firestore.collection('conversations').add(newChatRoom);
            navigate(`${PATH_DASHBOARD.chat.root}/${doc.id}`);
            dispatch(
                slice.actions.newConversationCreate(doc.id)
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const projectConversationSendMessage = (message, callback) => {
    return async (dispatch, getState) => {
        try {
            const {conversationId, ...rest} = message;
            const date = serverTime();
            await firestore.collection('project')
                .doc(conversationId).collection('messages').add({...rest, createdAt: date});

            isFunction(callback) && callback();
        } catch (e) {
            console.error(e);
        }
    };
};