import React, { useMemo, useCallback, useState } from 'react'
import useAuth from "src/hooks/useAuth";

import { Dialog, Paper } from "@mui/material";
import { getFileFormat } from "src/utils/getFileFormat";
import WordFileEditor from "src/components/WordFileEditor";
import PdfFileEditor from "src/components/PdfFileEditor";
import ExcelFileEditor from "src/components/ExcelFileEditor";
import LightboxModal from "src/components/LightboxModal";

export default function Reader({ url, file, open, onClose }) {
    const { user } = useAuth()
    const [selectedImage, setSelectedImage] = useState(0);

    const imagesLightbox = [file]?.filter(one => one?.type?.includes('image')).map(att => att.url);

    const handleUpdate = useCallback((...args) => {
        //console.log('update doc args', args)
    }, [])

    const readerProps = useMemo(() => ({
        file,
        email: user?.email,
        fileId: file?.id || '',
        fileName: file?.name || '',
        pathUrl: file?.url || '',
        onClose,
        handleUpdate
    }), [file, handleUpdate, onClose, user?.email])

    const format = useMemo(() => getFileFormat(file?.url || ' '), [file?.url])
    const isImage = useMemo(() => format === 'image', [format])

    return (
        <>


            {isImage && (
                <LightboxModal
                    images={imagesLightbox}
                    photoIndex={selectedImage}
                    setPhotoIndex={setSelectedImage}
                    isOpen
                    onClose={onClose}
                />
            )}


            {
                !isImage && open && file && (
                    <Dialog fullScreen fullWidth open onClose={onClose}>
                        <Paper square sx={{ bgcolor: 'transparent', width: '100%', height: '100vh' }}>
                            {format === 'word' && <WordFileEditor {...readerProps} />}

                            {format === 'pdf' && <PdfFileEditor{...readerProps} />}

                            {format === 'excel' && <ExcelFileEditor{...readerProps} />}
                        </Paper>
                    </Dialog>
                )

            }

        </>
    )
}