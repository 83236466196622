import { isEmpty } from 'lodash';
import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'src/redux/store';

/**
 *
 * @returns {{loading: boolean, tasks: Array}}
 */
export default function useTaskByWeek({ first, end, userId, projectId }) {
  const [loading, setLoading] = React.useState(false);

  const getTasks = useSelector((state) => state.firestore.ordered['task_by_weeks_by_User']);
  const tasks = React.useMemo(() => (isEmpty(getTasks) ? [] : getTasks), [getTasks]);

  useFirestoreConnect(() => [
    {
      collection: 'tasks',
      where: [
        ['deadLine', '>=', first],
        ['deadLine', '<=', end],
        ['projectKey', '==', projectId],
        ['canAccessId', 'array-contains', userId]
      ],
      orderBy: [['deadLine', 'desc']],
      storeAs: 'task_by_weeks_by_User'
    }
  ]);

  React.useEffect(() => {
    setLoading(!isLoaded(getTasks));
  }, [getTasks]);

  return { loading, tasks };
}
