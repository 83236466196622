import {keys} from 'lodash'
import {gDate, fDate} from "../utils/formatTime";
import {format} from "date-fns";
import {fr as LocalFr} from "date-fns/locale";

export function getCursor(date) {
    const parsed = gDate(date);

    const year = parsed.getFullYear();
    const month = parsed.getMonth();
    const day = parsed.getDate();

    return new Date(year, month, day);
}

function generate(list = []) {
    const map = {};

    list.forEach(el => {
        const cursor = getCursor(el.createdAt);
        const isIncluded = Boolean(keys(map).filter(key => key.toString() === cursor.toString()).length);

        if (isIncluded) {
            map[cursor] = [...map[cursor], el];
        } else {
            map[cursor] = [el];
        }
    })

    return keys(map).map(cursor => ({
        cursor,
        messages: map[cursor]
    }))

}

export const gfDate = date => {
    const parsed = gDate(date);
    if (parsed === null) return ''
    const year = parsed.getFullYear();
    const isTheCurrentYear = year === (new Date()).getFullYear()


    return date ? format(parsed, `dd MMM yyyy`, {
        locale: LocalFr
    }) : null;
};

export default function paginateChatMessage(messages = []) {
    return generate(messages)?.map(el => {
        const todayCursor = getCursor(new Date());
        const isToDay = todayCursor.toString() === el?.cursor.toString();
        const title = isToDay ? "Aujourd'hui" : gfDate(el?.cursor);

        return {
            ...el,
            title
        }
    })
}