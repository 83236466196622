import axios from 'axios'
import {createSlice} from '@reduxjs/toolkit'
import {DateTime} from 'luxon'
import {gDate} from "src/utils/formatTime";

const slice = createSlice({
    name: 'woldTime',
    initialState: {
        snap: null,
        initAt: null,
    },
    reducers: {
        setSnap: (state, action) => {
            state.snap = action.payload;
            state.initAt = new Date()
        }
    }
})

const worldTimeReducer = slice.reducer

export default worldTimeReducer

export const selectWorldTime = (store) => {
    const {snap = null, initAt = null} = store?.worldTime;
    return {snap, initAt, now: getWTNow(snap, initAt)}
}

export const getWTNow = (snap, initAt) => {
    if (snap === null) return null;
    if (initAt === null) return null;

    const moment = DateTime.now();
    const diff = moment.diffNow("milliseconds")

    return DateTime.fromJSDate(snap).plus({milliseconds: diff.toMillis()}).toJSDate()
}

export const initWorldTime = () => {
    return async (dispatch, getStore) => {
        try {

            const snap = await axios.get("https://worldtimeapi.org/api/ip");

            const date = gDate(snap.data['utc_datetime'])

            dispatch(slice.actions.setSnap(gDate(snap.data['utc_datetime'])))

        } catch (e) {
            //console.log(e)
        }
    }
}