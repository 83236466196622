import {firestore} from 'src/contexts/FirebaseContext'

export const updateDisponibility = ({data, onResolve}) => {
    return async () => {
        try {
            const {id, ...rest} = data
            await firestore.collection('dispobility').doc(id).set(rest, {merge: true})

            onResolve && onResolve()
        } catch (e) {
            //console.log(e)
        }
    }
}