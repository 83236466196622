import {
  Divider,
  IconButton,
  Stack,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Input,
  Tooltip,
  Badge
} from '@mui/material';
import { Attachment, Search, Send, Close } from '@mui/icons-material';
import createAvatar from 'src/utils/createAvatar';
import { useMemo, useState } from 'react';
import { transformUser } from 'src/hooks/useConversation';
import { MAvatar } from '../../components/@material-extend';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { useConverseProvider } from './ConverseProvider';
import useConversation from '../../hooks/useConversation';
import LoadingView from '../../components/LoadingView';
import useToggle from '../../hooks/useToggle';
import { searchList } from '../../utils/array';
import useConversationUnreadMessages from '../../hooks/useConversationUnreadMessages';
import EllipsisText from 'src/components/EllipsisText';
import { Icon } from '@iconify/react/dist/iconify';
import Label from 'src/components/Label';

export default function ConverseConversations() {
  const { list, loading } = useConversation();
  const { goToForm, goToRoom } = useConverseProvider();
  const [keyword, setKeyword] = useState('');
  const filtered = searchList(
    list?.map((el) => ({ ...el, _helper: `${el?.name} ${el?.lastMessage || ''}` })),
    keyword
  );

  return (
    <Box>
      <ConversationHeader goToForm={goToForm} keyword={keyword} setKeyword={setKeyword} />

      <Divider />
      <Stack height={480}>
        <Scrollbar>
          {loading ? (
            <LoadingView />
          ) : (
            <List dense>
              {filtered.map((e) => (
                <ConversationItem key={e.id} conversation={e} onTap={goToRoom} />
              ))}
            </List>
          )}
        </Scrollbar>
      </Stack>
    </Box>
  );
}

const ConversationHeader = ({ goToForm, keyword = '', setKeyword }) => {
  const { open, handleOpen, handleClose } = useToggle();

  const handleClosing = () => {
    setKeyword('');
    handleClose();
  };
  return (
    <>
      {!open && (
        <Stack direction={'row'} px={2} py={1} justifyContent={'space-between'}>
          <Stack justifyContent={'center'}>
            <Typography fontWeight={'bold'}>Chat</Typography>
          </Stack>

          <Stack direction={'row'} spacing={1}>
            <IconButton onClick={handleOpen}>
              <Search />
            </IconButton>

            <Tooltip title={'Nouvelle conversation'}>
              <IconButton onClick={goToForm}>
                <Iconify icon={'material-symbols:chat-add-on'} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}

      {open && (
        <Stack direction={'row'} px={2} py={1.5} justifyContent={'space-between'}>
          <Input
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
            size={'small'}
            fullWidth
            variant="standard"
            placeholder={'Rechercher ...'}
            disableUnderline
            endAdornment={
              <Stack direction={'row'}>
                <IconButton size={'small'} onClick={handleClosing}>
                  <Close />
                </IconButton>
              </Stack>
            }
          />
        </Stack>
      )}
    </>
  );
};

const ConversationItem = ({ conversation, onTap }) => {
  const unRead = useConversationUnreadMessages(conversation?.id);
  const count = unRead >= 100 ? 99 : unRead;
  const { participants = [], lastMessage = '' } = conversation;

  const users = useMemo(() => participants.map((e) => transformUser(e)), [participants]);

  const selected = users[0] || {};

  const avatarI = createAvatar(conversation?.name);

  const size = 35;

  const handleTap = () => {
    onTap(conversation);
  };
  const truncate = (val = '') => (val.length > 30 ? `${val.substring(0, 30)} ...` : val);

  const name = truncate(conversation?.name);

  // console.log('conv', conversation);
  const lastWidget = useMemo(() => {
    const size = 12;
    const params = { width: size, height: size };

    const Container = ({ text, icon }) => {
      return (
        <Stack direction={'row'} spacing={0.5}>
          <Stack justifyContent={'center'}>
            <Icon icon={icon} {...params} />
          </Stack>
          <EllipsisText material text={text} style={{ fontWeight: 100, fontSize: 12 }} />
        </Stack>
      );
    };

    if (lastMessage === 'Note vocale') {
      return <Container text={'Note vocale'} icon={'ant-design:audio-outlined'} />;
    }

    if (lastMessage === 'Audio') {
      return <Container text={'Audio'} icon={'ic:sharp-audiotrack'} />;
    }
    if (lastMessage === 'Image') {
      return <Container text={'Image'} icon={'bi:image-fill'} />;
    }
    if (lastMessage === 'Illustration') {
      return <Container text={'Illustration'} icon={'bi:image-fill'} />;
    }
    if (lastMessage === 'Video') {
      return <Container text={'Video'} icon={'bi:camera-video-fill'} />;
    }
    if (lastMessage === 'Document') {
      return <Container text={'Document'} icon={'fluent:document-16-filled'} />;
    }
    if (lastMessage === 'Fichier attaché') {
      return <Container text={'Fichier attaché'} icon={'fluent:document-16-filled'} />;
    }
    return <EllipsisText text={lastMessage} material style={{ fontWeight: 100, fontSize: 12, color: 'grey.600' }} />;
  }, [lastMessage]);

  return (
    <ListItem
      onClick={handleTap}
      secondaryAction={unRead !== 0 && <Label color="success"> {count} </Label>}
      disablePadding
      sx={{ px: 0.8 }}
    >
      <ListItemButton role={undefined} sx={{ borderRadius: 2 }}>
        <ListItemAvatar>
          <MAvatar
            alt={selected?.name}
            src={conversation?.avatar || selected?.avatar}
            sx={{ width: size, height: size, fontSize: 12 }}
            color={selected?.avatar ? 'default' : avatarI.color}
          >
            {avatarI.name}
          </MAvatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={name}
          secondary={lastWidget}
          sx={{ fontWeight: 'bold', fontSize: 15 }}
          secondaryTypographyProps={{ fontWeight: 100, fontSize: 12 }}
        />
      </ListItemButton>
    </ListItem>
  );
};
