import { Grid, Paper, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useV4ProjectContext } from '..';
import V4ProjectContentKanbanColumnAdd from './V4ProjectContentKanbanColumnAdd';
import V4ProjectCotentKanbanBody from './V4ProjectCotentKanbanBody';
import V4ProjectCotentKanbanToolbar from './V4ProjectCotentKanbanToolbar';

const SkeletonLoad = (
  <Stack width={1} height="90vh">
    <Grid container spacing={3}>
      {[...Array(3)].map((_, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Skeleton variant="rectangular" width="100%" sx={{ height: '80vh', borderRadius: 2 }} />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export default function V4ProjectCotentKanban() {
  const { board, loading, projectId } = useV4ProjectContext();
  const { columnOrder, columns } = board;
  //console.log({projectId});
  return (
    <Stack>
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            direction="row"
            alignItems="flex-start"
            spacing={2}
            sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
          >
            {!!columnOrder?.length &&
              columnOrder?.map((colId, index) => {
                const column = columns[colId];
                return column && <Operation key={colId + index} column={column} index={index}  />;
              })}
            {loading && SkeletonLoad}
            {provided.placeholder}
            <V4ProjectContentKanbanColumnAdd loading={loading} projectKey={projectId}  />
          </Stack>
        )}
      </Droppable>
    </Stack>
  );
}

const Operation = ({ column, index }) => {
  const [hover, onHover, endHover] = useToggleV2();
  const [openTasknew, onOpenTasknew, onCloseTasknew] = useToggleV2();
  const { taskRights:rigths } = useV4ProjectContext();

  return (
    <Draggable draggableId={column?.id} index={index} isDragDisabled={!rigths?.edit}>
      {(provided) => (
        <Stack
          {...provided.draggableProps}
          ref={provided.innerRef}
          component={Paper}
          variant={hover ? 'outlined' : 'elevation'}
          spacing={0.5}
          minWidth={290}
          maxWidth={290}
          width={290}
          sx={{ bgcolor: 'transparent', p: 1 }}
        >
          <Stack width={1} {...provided.dragHandleProps}>
            <V4ProjectCotentKanbanToolbar
              column={column}
              hover={hover}
              onHover={onHover}
              endHover={endHover}
              onOpenTasknew={onOpenTasknew}
              rights={rigths}
            />
          </Stack>
          <V4ProjectCotentKanbanBody
            column={column}
            hover={hover}
            onHover={onHover}
            endHover={endHover}
            onOpenTasknew={onOpenTasknew}
            openTasknew={openTasknew}
            onCloseTasknew={onCloseTasknew}
            rights={rigths}
          />
        </Stack>
      )}
    </Draggable>
  );
};
