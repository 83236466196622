import { useMemo } from 'react';
import useProject from 'src/hooks/useProject';
import useAuth from 'src/hooks/useAuth';

import { uniqBy, isObject } from 'lodash';

import MenuPopover, { CustomMenuPopover } from 'src/components/MenuPopover';
import { SelectCollaborates } from 'src/section/project/ProjectCardOPtion';
import { transformUserToAccess } from 'src/helpers/user';

export default function UserPicker({
  users = [],
  customList = null,
  anchorEl,
  title = 'intervenants',
  field = 'assignee',
  bottomRight = false,
  restrictToProjectFromTask = null,
  single = false,
  preTitle = null,
  open = false,
  onClose,
  onChange
}) {
  const { user } = useAuth();

  const { projects } = useProject({ id: user.id, status: ['open'] });

  const projectId = useMemo(() => {
    const task = restrictToProjectFromTask;
    return task?.projectId || task?.projectKey || task?.idProject;
  }, [restrictToProjectFromTask]);

  const fixedUsers = useMemo(() => {
    const project = projects?.find((el) => el?.id === projectId);

    const persons = [...(project?.canAccess || []), ...(project?.managers || []), project?.createBy]
      ?.filter((el) => isObject(el))
      ?.map((el) => transformUserToAccess(el))
      ?.map((el) => ({
        ...el,
        displayName: el?.name
      }));

    return uniqBy(persons, 'id');
  }, [projectId, projects]);

  return (
    <>
      {open && (
        <CustomMenuPopover
          open={open}
          onClose={onClose}
          anchorEl={anchorEl?.current}
          zIndex={(theme) => theme?.zIndex?.modal + 5}
          width={400}
          disabledArrow
          {...(bottomRight && { transformOrigin: { vertical: 'bottom', horizontal: 'right' } })}
          sx={{
            height: { xs: 1, md: 400 },
            bgcolor: 'transparent',
            border: 'none',
            outline: 'none',
            boxShadow: 'none'
          }}
        >
          <SelectCollaborates
            title={title}
            field={field}
            currents={users}
            onClose={onClose}
            onChange={onChange}
            preTitle={preTitle}
            single={single}
            customList={customList || fixedUsers}
          />
        </CustomMenuPopover>
      )}
    </>
  );
}
