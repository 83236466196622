import { useFormik } from 'formik';
import * as Yup from 'yup';

import { themeColor } from 'src/constants/color';

import {
  Dialog,
  Button,
  DialogTitle,
  Stack,
  Paper,
  Container,
  TextField,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import Center from 'src/components/Center';
import { capitalize } from 'lodash';
import MailLoginOutlook from 'src/section/mail/MailLoginOutlook';
import { useToggleV2 } from 'src/hooks/useToggle';
import Iconify from 'src/components/Iconify';
import { mailHoverColor } from 'src/constants/mail';
import { CustomMailForm } from 'src/section/mail_v2/MailV2CustomLogin';

export default function MailLogin({ open, onClose, onLink }) {
  const [visible, show] = useToggleV2();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
        fullWidth
        fullScreen
        PaperProps={{ sx: { bgcolor: 'transparent' } }}
      >
        <Center>
          <Container maxWidth={'xs'}>
            <Paper sx={{ borderRadius: 1 }} variant="outlined">
              <Stack p={2} pb={4} width={1} minHeight={'50vh'}>
                <Stack width={1} pb={1} pl={3} direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'h6'}>Lier un compte mail</Typography>
                  <IconButton onClick={onClose}>
                    <Close />
                  </IconButton>
                </Stack>

                {visible && <CustomMailForm onClose={onClose} />}

                {!visible && (
                  <Stack width={1} px={3} spacing={1}>
                    <Stack spacing={2}>
                      <AuthBtn
                        label={'Compte professionnel'}
                        onClick={show}
                        icon={<Iconify icon={'material-symbols:mail-outline-rounded'} fontSize={18} />}
                      />
                    </Stack>

                    <Stack direction={'row'} py={1}>
                      <Divider sx={{ width: '40%', mt: 1.5 }} />

                      <Typography width={1} align={'center'}>
                        ou
                      </Typography>

                      <Divider sx={{ width: '40%', mt: 1.5 }} />
                    </Stack>

                    <Stack spacing={1}>
                      <MailLoginOutlook />
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Paper>
          </Container>
        </Center>
      </Dialog>
    </>
  );
}

const FormField = ({ label, helperText, error, anchor, InputProps, icon, ...rest }) => {
  const iconSize = { height: 20, width: 20 };

  return (
    <Stack spacing={1}>
      <Typography pl={0.5} fontSize={14}>
        {capitalize(label)}
      </Typography>
      <TextField
        label={' '}
        size={'small'}
        InputProps={{
          startAdornment: <Icon icon={icon} {...iconSize} />
        }}
      />
      {helperText && (
        <Typography variant={'caption'} color={error ? 'red' : 'black'} fontSize={12}>
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};

export const AuthBtn = ({ label, icon, onClick }) => {
  return (
    <Stack
      direction={'row'}
      onClick={onClick}
      sx={{
        borderStyle: 'solid',
        backgroundColor: 'white',
        borderWidth: 1,
        borderRadius: 1,
        borderColor: (t) => t.palette.divider,
        py: 1.4,
        pl: 5,
        cursor: 'pointer',
        ':hover': {
          bgcolor: (t) => mailHoverColor
          // color: 'white'
        }
      }}
    >
      <Stack width={60} height={1} direction={'row'} pt={0.6}>
        {icon}
      </Stack>

      {label}
    </Stack>
  );
};
