import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import roundAddAPhoto from '@iconify/icons-ic/round-add-a-photo';
// material
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
import { Box, Tooltip, Typography, IconButton, FormHelperText, CircularProgress } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
//
// import { cloudinaryConfig } from '../../config';

import { useFirebase } from 'react-redux-firebase';
import useAuth from 'src/hooks/useAuth';
import DeleteIcon from '@mui/icons-material/Delete';

// ----------------------------------------------------------------------

// const CLOUDINARY_KEY = cloudinaryConfig.cloudinaryKey;
// const CLOUDINARY_PRESET = cloudinaryConfig.cloudinaryPreset;
// const CLOUDINARY_URL = cloudinaryConfig.cloudinaryUrl;

const PHOTO_SIZE = 3145728; // bytes
const FILE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const LoadingStyle = styled('div')(({ theme }) => ({
  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.grey[900], 0.72)
}));

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {
    opacity: 0.72
  }
}));

// ----------------------------------------------------------------------

UploadMAvatar.propTypes = {
  disabled: PropTypes.bool,
  caption: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default function UploadMAvatar({ disabled, caption, error = false, value: file, onChange: setFile, ...other }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const firebase = useFirebase();

  const { user: currentUser } = useAuth();
  const { id } = currentUser;

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      const checkSize = file.size < PHOTO_SIZE;
      const checkType = FILE_FORMATS.includes(file.type);

      if (!checkSize) {
        setIsError('size-invalid');
      }

      if (!checkType) {
        setIsError('type-invalid');
      }

      try {
        if (checkSize && checkType) {
          setIsLoading(true);

          const storageRef = firebase.storage().ref();
          const fileUpload = storageRef.child(`${id}/${file?.name}`).put(file);

          return await fileUpload.on(
            'state_changed',
            (snap) => { },
            (error) => {
              console.error(error);
            },
            () => {
              fileUpload.snapshot.ref.getDownloadURL().then((url) => {
                if (isMountedRef.current) {
                  setFile(url);
                  setIsLoading(false);
                  setIsError(null);
                }
              });
            }
          );
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    },
    [isMountedRef, setFile, firebase, id]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: 'image/*',
    disabled
  });

  return (
    <>
      <RootStyle {...other}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            })
          }}
        >
          <input {...getInputProps()} />

          {file && <Box component="img" alt="avatar" src={file} sx={{ zIndex: 8, objectFit: 'cover' }} />}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 }
              })
            }}
          >
            <Box component={Icon} icon={roundAddAPhoto} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{file ? 'Update photo' : 'Changer la photo'}</Typography>
          </PlaceholderStyle>

          {isLoading && (
            <LoadingStyle>
              <CircularProgress size={32} thickness={2.4} color="primary" />
            </LoadingStyle>
          )}
        </DropZoneStyle>
      </RootStyle>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {isError === 'size-invalid' && (
          <FormHelperText error>{`File is larger than ${fData(PHOTO_SIZE)}`}</FormHelperText>
        )}

        {isError === 'type-invalid' && (
          <FormHelperText error>le fichier doit être de type *.jpeg, *.jpg, *.png, *.gif</FormHelperText>
        )}
      </Box>
    </>
  );
}
