import { alpha, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { InputWithMention } from 'src/components/CustomInput';
import { themeColor } from 'src/constants/color';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { dispatch } from 'src/redux/store';

const style = {
  control: {
    fontSize: 20
  },
  '&multiLine': {
    input: {
      fontWeight: 'bold',
      padding: 1,
      border: 'none',
      outlineColor: 'transparent',
      outline: 'none'
    }
  }
};

/**
 *
 * @param {{ value: {name: string, name_mentions: Array}, id: string, onUpdate: function, disabled: boolean }} props
 * @returns
 */
export default function TaskTitleInput({ value, task, onUpdate ,disabled = false}) {
  const [values, setValues] = useState({ name: value?.name || '', name_mentions: value?.name_mentions || [] });

  const handleChange = (value) => {
    setValues((prev) => ({ ...prev, name: value.newValue, name_mentions: value.mentions }));
  };

  const onSave = () => {
    if (!isEmpty(values.name)) {
      dispatch(onUpdate(task, values, () => {}));
    }
  };

  const onKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      onSave();
    }
  };

  return (
    <Stack
      width={1}
      sx={{
        py: 1,
        px: 0.5,
        borderRadius: 1,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        ':hover': { borderColor: themeColor.SMOKY_BLACK }
      }}
    >
      <InputWithMention
        value={values.name}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        onKeyDown={handleKeyDown}
        onBlur={onSave}
        style={style}
        placeholder="Titre de la tâche"
        disabled={disabled}
      />
    </Stack>
  );
}
