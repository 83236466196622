import { useEffect, useState, useRef, useMemo } from 'react';
import { useMailContext } from 'src/section/mail/MailContext';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';

import { humanFileSize } from 'src/utils/formatNumber';
import { getFileFormat, getFileThumb, getFileThumbUrl } from 'src/utils/getFileFormat';

import { Stack, Typography, CircularProgress, Tooltip, Box, Grid } from '@mui/material';
import { MenuPopoverCenter } from 'src/components/MenuPopover';
import { MenuLine } from 'src/components/MenuLine';

import Iconify from 'src/components/Iconify';

import './shimmer.css';
import AttachmentReader from 'src/components/AttachmentReader';
import { downloadFile } from 'src/helpers/downloadFile';
import { MAIL_PLATFORM } from 'src/constants/mail';
import { nanoid } from '@reduxjs/toolkit';

export default function MailDetailAttachment({ mail }) {
  const { getMailAttachments, setReadMail, updateMail } = useMailContext();

  const { open, handleOpen, handleClose } = useToggle();
  const [selectedFile, setSelectedFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const { big, mini } = useMemo(() => {
    const buff = files?.map((file) => ({
      file,
      name: file?.name,
      type: file?.type,
      size: file?.size,
      id: file?.id || nanoid(),
      format: getFileFormat(file?.name)
    }));
    return {
      big: buff?.filter((file) => ['image', 'video'].includes(file?.format)),
      mini: buff?.filter((file) => !['image', 'video'].includes(file?.format))
    };
  }, [files]);

  const handleReadFile = (file) => {
    setSelectedFile(file);
    handleOpen();
  };

  const handleClosing = () => {
    handleClose();
    setSelectedFile(null);
  };

  const handleDownload = (file) => {
    downloadFile(file?.url, file?.name);
  };

  useEffect(() => {
    setFiles([]);
    setLoading(false);

    if (mail?.hasAttachments && mail?.account && mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      setLoading(true);

      getMailAttachments(mail.account, mail.folder, mail.id, (files) => {
        setFiles(files);
        setLoading(false);
        //console.log({ files });
        updateMail(mail.account, mail.folder, mail.id, { files });
      });
    } else if (mail?.files?.length > 0) {
      setFiles(mail.files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mail?.id]);

  return (
    <Stack spacing={1}>
      {loading && (
        <Grid container spacing={2}>
          {[...Array(mail?.files?.length || 1)]?.map((el, index) => (
            <Grid item key={`big-${index}`}>
              <Stack
                className={'skeleton-box'}
                width={160}
                height={120}
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: (t) => t.palette.divider,
                  borderRadius: 1
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Grid container spacing={2}>
        {big?.map((el) => (
          <Grid item key={el?.id}>
            <ItemView file={el} onRead={handleReadFile} onDownload={handleDownload} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        {mini?.map((el) => (
          <Grid item key={el?.id}>
            <ItemView file={el} onRead={handleReadFile} onDownload={handleDownload} />
          </Grid>
        ))}
      </Grid>

      <AttachmentReader file={selectedFile} open={open} onClose={handleClosing} />
    </Stack>
  );
}

const ItemView = ({ file, onRead, onDownload }) => {
  const ref = useRef();
  const [hover, onHover, onLeave] = useToggleV2();
  const [optionsVisible, onOpenOptions, onCloseOption] = useToggleV2();

  const format = getFileFormat(file?.name);
  const miniMode = !['image', 'video'].includes(format);
  const _file = file?.file;
  //TODO : utiliser la fonctiongetTumbnel pour afficher la preview si le fichoeir nest ni une image ni une video
  const isValidFileOrBlob = (_file) => {
    // Vérifiez si _file est une instance de Blob ou File
    if (!(_file instanceof Blob) && !(_file instanceof File)) {
      return false;
    }

    // Vérifiez si le type de contenu est supporté
    const supportedContentTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];
    if (!supportedContentTypes.includes(_file.type)) {
      return false;
    }

    // Vérifiez si les propriétés name et type sont correctement remplies
    if (!_file.name || !_file.type) {
      return false;
    }

    return true;
  }
  let preview = null;
  try {

    preview = isValidFileOrBlob(_file) ? URL.createObjectURL(_file) : getFileThumbUrl(_file?.type, _file?.url);
  }
  catch (e) {
    console.error(e);
  }

  //console.log({ preview });
  return (
    <>
      {!miniMode && (
        <Stack
          onMouseLeave={onLeave}
          onMouseEnter={onHover}
          width={160}
          height={120}
          sx={{
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: (t) => t.palette.divider,
            borderRadius: 1,
            bgcolor: '#f5f5f5'
          }}
        >
          <Stack
            width={160}
            height={120}
            p={1}
            justifyContent={'center'}
            onClick={() => onRead(_file)}
            sx={{ cursor: 'pointer' }}
          >
            {format === 'video' && (
              <video controls={false}>
                <source src={preview} type={_file?.type} />
                <track default kind="captions" srcLang="fr" src="" />
              </video>
            )}

            {format === 'image' && (
              <Box component="img" alt={_file?.name} src={preview} height={'100%'} width={'100%'} />
            )}
          </Stack>

          {(hover || optionsVisible) && (
            <Stack
              width={160}
              height={50}
              sx={{
                position: 'absolute',
                mt: 8.6,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5
              }}
            >
              <Stack
                width={160}
                height={50}
                p={0.5}
                bgcolor={'black'}
                color={'white'}
                sx={{
                  opacity: 0.5,
                  position: 'absolute',
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5
                }}
              >
                <Tooltip title={_file?.name}>
                  <Typography variant={'caption'} noWrap whiteSpace={'nowrap'} textOverflow={'ellipsis'} width={160}>
                    {_file?.name}
                  </Typography>
                </Tooltip>

                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'caption'}>{humanFileSize(_file?.size)}</Typography>

                  <Stack ref={ref} onClick={onOpenOptions} p={0.3} sx={{ cursor: 'pointer' }}>
                    <Iconify icon={'ic:baseline-keyboard-arrow-down'} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}

      {miniMode && (
        <Stack
          direction={'row'}
          spacing={1}
          width={160}
          height={50}
          px={1}
          sx={{
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: (t) => t.palette.divider,
            borderRadius: 1
            // bgcolor: '#f5f5f5'
          }}
        >
          <Stack width={30} height={30} alignSelf={'center'} onClick={() => onRead(file)}>
            <Box component="img" src={getFileThumbUrl(file?.type, file?.url)} height={30} width={30} />
          </Stack>

          <Stack width={1} height={1} justifyContent={'center'} onClick={() => onRead(file)} sx={{ cursor: 'pointer' }}>
            <Tooltip title={file?.name}>
              <Typography variant={'caption'} noWrap whiteSpace={'nowrap'} textOverflow={'ellipsis'} width={90}>
                {file?.name}
              </Typography>
            </Tooltip>

            <Typography variant={'caption'} fontSize={9}>
              {humanFileSize(file?.size)}
            </Typography>
          </Stack>

          <Stack alignSelf={'center'} ref={ref} onClick={onOpenOptions} p={0.3} sx={{ cursor: 'pointer' }}>
            <Iconify icon={'ic:baseline-keyboard-arrow-down'} />
          </Stack>
        </Stack>
      )}

      <OptionsMenu
        anchor={ref}
        open={optionsVisible}
        onClose={onCloseOption}
        onRead={() => onRead(_file)}
        onDownload={() => onDownload(_file)}
      />
    </>
  );
};

const OptionsMenu = ({ file, anchor, open, onClose, onRead, onDownload }) => {
  return (
    <MenuPopoverCenter anchorEl={anchor.current} open={open} onClose={onClose} disabledArrow width={'fit-content'}>
      <MenuLine icon={'material-symbols:download'} title={'Télécharger'} onClick={onDownload} />
    </MenuPopoverCenter>
  );
};