import axios from 'axios';
import { API_KEY } from 'src/config';


const instance = axios.create({
    baseURL: API_KEY,
});

const responseBody = (response) => response.data;

const axiosRequest = {
  post: (url, data = null) => instance.post(url, data).then(responseBody),
  put: (url, data = null) => instance.put(url, data).then(responseBody),
  get: (url) => instance.get(url).then(responseBody),
  delete: (url) => instance.delete(url).then(responseBody)
};

export default axiosRequest;
