import {useRef, useMemo, useCallback} from "react";
import {nanoid} from '@reduxjs/toolkit'

export default function useFilePicker({onPick}) {
    const fileInputRef = useRef();
    const imageInputRef = useRef();

    const handleFileSelection = useCallback((event) => {
        const files = [...(event.target.files ?? [])];
        // eslint-disable-next-line no-unused-expressions
        onPick && onPick(files?.map(file => Object.assign(file, {id: nanoid(), url: window.URL.createObjectURL(file)})))

        if (fileInputRef.current)
            fileInputRef.current.value = ''

        if (imageInputRef.current)
            imageInputRef.current.value = ''

    }, [onPick]);

    const pickFile = () => fileInputRef.current?.click()

    const pickImage = () => imageInputRef.current?.click()

    const fileInputProps = useMemo(() => ({
        type: 'file',
        multiple: true,
        ref: fileInputRef,
        style: {display: 'none'},
        onChange: handleFileSelection,
        onDone:args=>console.log(args)
    }), [handleFileSelection]);

    const imageInputProps = useMemo(() => ({
        ...fileInputProps, accept: 'image/*', ref: imageInputRef
    }), [fileInputProps]);


    return {
        pickFile, pickImage,
        fileInputProps, imageInputProps
    }
}