import { auth, firestore } from "src/contexts/FirebaseContext"


const reminderCollection = firestore.collection("reminders")

export function createRappel(rappel, onResolve, onReject) {
    return async () => {
        try {

            const data ={
                ...rappel,
                createdAt: new Date(),
                createdById: auth.currentUser?.uid
            }

            const result = await reminderCollection.add(data)

            if (onResolve) onResolve(result)
        } catch (error) {
            if (onReject) onReject()
            console.error(error)
        }
    }
}

export function editRappel(rappel, onResolve, onReject) {
    return async () => {
        try {

            const { id, ...rest } = rappel

            await reminderCollection.doc(id).set({
                ...rest,
                updatedAt: new Date()
            }, { merge: true })

            if (onResolve) onResolve()
        } catch (error) {
            if (onReject) onReject()
            console.error(error)
        }
    }
}

export function deleteRappel(rappelId, onResolve, onReject) {
    return async () => {
        try {

            await reminderCollection.doc(rappelId).delete()

            if (onResolve) onResolve()
        } catch (error) {
            if (onReject) onReject()
            console.error(error)
        }
    }
}