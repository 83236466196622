import { ClickAwayListener, IconButton, Paper, Stack } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import { dispatch } from 'src/redux/store';
import { TaskType } from 'src/models/task_m';
import TextMaxLine from 'src/components/TextMaxLine';
import { Draggable } from 'react-beautiful-dnd';
import { useTaskV4Context } from 'src/contexts/TaskContext';
import CIconButton from 'src/components/CIconButton';
import { useToggleV2 } from 'src/hooks/useToggle';
import { MuiCustomInput } from 'src/components/CustomInput';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { isEmpty } from 'lodash';

export default function V4ProjectWeekCalendarBody({ tasks = [] }) {
  return (
    <Stack spacing={1}>
      {tasks?.map((task, index) => (
        <CalendarTaskLine key={task?.id + index} card={task} index={index} />
      ))}
    </Stack>
  );
}

const CalendarTaskLine = ({ card = TaskType, index }) => {
  //TODO : add right when open task details
  const [task, setTask] = useState(card);
  const { openTaskDetails } = useTaskV4Context();
  const [hover, onHover, endHover] = useToggleV2();
  const [edit, onEdit, closeEdit] = useToggleV2();

  const handleCompleted = () => {
    setTask((prev) => ({ ...prev, completed: !task?.completed }));
    dispatch(updateTaskWithoutProject(task, { completed: !task?.completed }, () => {}));
  };

  const handleChangeName = (e) => {
    const val = e.currentTarget.value;
    setTask((prev) => ({ ...prev, name: val }));
  };

  const onUpdate = () => {
    if (!isEmpty(task?.name)) {
      dispatch(updateTaskWithoutProject(task, { name: task?.name }, () => closeEdit()));
      closeEdit();
    }
  };

  let counter = 0;
  const handleKeyUp = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      if (counter < 1) {
        onUpdate();
        counter += 1;
        return;
      }
    }
  };

  return (
    <Draggable draggableId={card?.id} index={index} data-value={card}>
      {(provided, snapshot) => (
        <Stack position="relative" onMouseEnter={onHover} onMouseLeave={endHover}>
          <Stack
            {...provided.draggableProps}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            direction="row"
            component={Paper}
            p={1}
            variant="outlined"
            alignItems="center"
            onClick={() => (edit ? undefined : openTaskDetails(card))}
            onMouseEnter={!snapshot.isDragging ? onHover : undefined}
            onMouseLeave={endHover}
          >
            <IconButton size="small" sx={{ p: 0.2 }} onClick={handleCompleted}>
              <Iconify
                icon={!task?.completed ? 'mingcute:check-circle-line' : 'ph:check-circle-fill'}
                color={task?.completed ? 'green' : 'inherit'}
                sx={{ height: 20, width: 20 }}
              />
            </IconButton>

            <Stack width={1}>
              {!edit ? (
                <TextMaxLine fontSize={13} fontWeight={600} line={3} sx={{ cursor: 'pointer' }}>
                  {task?.name}
                </TextMaxLine>
              ) : (
                <ClickAwayListener onClickAway={onUpdate}>
                  <MuiCustomInput
                    multiline
                    maxRows={3}
                    minRows={1}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    value={task.name}
                    onChange={handleChangeName}
                    autoFocus
                    fullWidth
                    size="small"
                    sx={{
                      py: 0.3,
                      fontSize: 13,
                      border: 'none'
                    }}
                  />
                </ClickAwayListener>
              )}
            </Stack>
          </Stack>
          {hover && !edit && (
            <Stack
              sx={{ bgcolor: 'white' }}
              position="absolute"
              top={5}
              right={5}
              onClick={onEdit}
              onMouseEnter={onHover}
            >
              <CIconButton title="editer">
                <Iconify icon="eva:edit-fill" />
              </CIconButton>
            </Stack>
          )}
        </Stack>
      )}
    </Draggable>
  );
};
