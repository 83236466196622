import { createSlice } from '@reduxjs/toolkit';
import firestore from 'src/utils/firestore';

const initialState = {
    list: [],
    isLoading: false,
    error: null,
    cursor: null,
    hasNoMore: false
}

const slice = createSlice({
    name: 'oldtask',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getOldTaskSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.list = action.payload;
        },

    }
});

export default slice.reducer;


export function createOldTask(task, callback= null) {
    return async (dispatch) => {

        dispatch(slice.actions.startLoading());

        try {

            const doc = await firestore.collection('oldtask').add(task);

            if(callback) callback(doc.id);
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function updateOldTask(task, callback= null) {
    return async (dispatch) => {

        dispatch(slice.actions.startLoading());
        const {id, ...rest} =task;
        try {
            await firestore.collection('oldtask').doc(id).set(rest, {merge: true});
            if(callback) callback(id);
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function deleteOldTask(taskId, callback= null) {
    return async (dispatch) => {

        dispatch(slice.actions.startLoading());

        try {
            await firestore.collection('oldtask').doc(taskId).delete();
            if(callback) callback();
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function updateHistoryOldTask(history, taskId, callback= null) {
    return async (dispatch) => {

        dispatch(slice.actions.startLoading());

        try {
            const colRef = firestore.collection('oldtask').doc(taskId);
            firestore.runTransaction((transac)=>{
                return transac.get(colRef).then((sfDoc) => {
                    if(sfDoc.exists){
                        let histories = sfDoc.data().history;
                        const exist = histories.findIndex(h => h.id === history.id);
                        if(exist !== -1){
                            histories.splice(exist, 1, history);  
                            transac.update(colRef, { history: [...histories]});
                        }else{
                            transac.update(colRef, { history: [...histories, history]});
                        }
                    }
                })
            }).then(() => {
                if(callback) callback();
            })
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function deleteHistoryOldTask(historyId, taskId, callback= null) {
    return async (dispatch) => {

        dispatch(slice.actions.startLoading());

        try {
            const colRef = firestore.collection('oldtask').doc(taskId);
            firestore.runTransaction((transac)=>{
                return transac.get(colRef).then((sfDoc) => {
                    if(sfDoc.exists){
                        let histories = sfDoc.data().history;
                        histories = histories.filter((hist) => hist.id !== historyId);
                        transac.update(colRef, { history: [...histories]})
                    }
                })
            }).then(() => {
                if(callback) callback();
            })
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function canAccessIsSetter(affect, oldTaskId){
    return  async (dispatch) =>{
        try {
            if(affect.length){
                var oldTaskRef = firestore.collection('oldtask').doc(oldTaskId);
                await firestore.runTransaction(async (tranc) => {
                    const sfDoc = await tranc.get(oldTaskRef);
                    if (sfDoc.exists) {
                        const canAccessId = sfDoc.data()?.canAccessId;
                        const newVal = [...canAccessId, ...affect];

                        tranc.update(oldTaskRef, { canAccessId: [...newVal] });
                    }
                })
            }
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}