import { gDate } from 'src/utils/formatTime';

export function todayDateFilter(date) {
  //TODO : remove dimanche 
  let _date = gDate(date);
  const now = new Date();
  const startToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const startTomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  return _date >= startToday && _date < startTomorrow;
}

export function thisWeekDateFilter(date) {
  let _date = gDate(date);
  const now = new Date();
  const startToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const dayOfWeek = now.getDay()-1;
  const daysUntilEndOfWeek = 6 - dayOfWeek;

  const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilEndOfWeek + 1);

  return _date >= startToday && _date <= endOfWeek;
}

export function nextWeekDateFilter(date1, date2) {
  let _date1 = gDate(date1);
  let _date2 = gDate(date2);
  const now = new Date();

  const dayOfWeek = now.getDay() - 1;
  const daysUntilEndOfWeek = 6 - dayOfWeek;

  const startEndOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilEndOfWeek + 1);
  const startNextWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilEndOfWeek + 7);

  return _date1 <= startNextWeek && _date2 >= startEndOfWeek;
}
